import React from "react";
import Marquee from "react-fast-marquee";

export default function HorizontalText({ text }) {
  return (
    <div className="font-primary text-main-color p-6 font-bold text-xl md:text-2xl lg:text-3xl">
      <Marquee
        speed={100}
        delay={2}
        className="whitespace-nowrap overflow-x-auto  scrollbar-hide md:scrollbar-default"
      >
        {text}{" "}
      </Marquee>
    </div>
  );
}
