import React  from "react";
import { NavLink } from "react-router-dom";
export default function Dropdown(props) {
  const { item  } = props;
  // const [isOpen, setIsOpen] = useState(false);
  const menuItems = item?.children ? item.children : [];


  // const transClass = isOpen ? "flex" : "hidden";

  return (
    <>
      <details className="group">
        <summary className="flex items-center justify-between gap-2 p-2 font-medium marker:content-none hover:cursor-pointer">
          <span className="flex gap-2">
            <NavLink to={item.route}>{item.title}</NavLink>
          </span>
          <svg
            className="w-5 h-5 text-gray-500 transition group-open:rotate-90"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </summary>

        <article className="px-4 pb-4">
          <ul className=" grid columns-1 gap-1 ">
            <li className="flex flex-col">
              {menuItems.map((item,index) => (
                <NavLink
                  key={index}
                  className="hover:bg-orange-300  py-1 "
                  to={item?.route || ""}
                >
                  {item.title}
                </NavLink>
              ))}
            </li>
          </ul>
        </article>
      </details>
    </>
  );
}
