import React, { useEffect } from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UIUX_Hero from "../components/Hero/uiux_Hero";
import Form from "../components/Form";
import FeaturesUIUX from "../components/Features/FeaturesUIUX";
import advertising from "../assets/imags/_lab/uiux.jpg";
import UXUIdesignBox from "../components/Service plan box/UXUIdesign";
import HorizontalText from "../components/Horizontal Text";
const UXUIdesign = () => {
  useEffect(() => {
    document.title = "The Lab - UX/UI Design  ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg8	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl "
          text="Guaranteeing user experience is in adequation with web performance. "
        />
        <UIUX_Hero
          img={advertising}
          text={
            <>
              We <span class="text-main-color"> map </span> and craft engaging{" "}
              <span class="text-main-color">experiences. </span>
            </>
          }
        />
        <section className=" ">
          <FeaturesUIUX />
          <HorizontalText
            text={
              <>
                <span className="underline">Clear</span> and
                <span className="underline"> easy</span> pricing structure.
                no-hidden fees. No headaches 🤯.
              </>
            }
          />
          <UXUIdesignBox />
          <Form />
        </section>

        <Footer />
      </div>
    </>
  );
};

export default UXUIdesign;
