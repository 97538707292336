import React from "react";
import Webflow from "../../assets/imags/_lab/Webflow.png";
import WordPress from "../../assets/imags/_lab/WordPress.png";
import Shopify from "../../assets/imags/_lab/Shopify.png";
import handy from "../../assets/imags/_lab/handy.png";
import buyhouseez from "../../assets/imags/_lab/buyhouseez.png";
import minori from "../../assets/imags/_lab/minori.png";
import republic from "../../assets/imags/_lab/s republic.png";
import toni from "../../assets/imags/_lab/toni odisho.png";
import toniandguy from "../../assets/imags/_lab/toniandguy.png";
import veronica from "../../assets/imags/_lab/veronica tharmalingan.png";
const Features = () => {
  const imgs = {
    handy: handy,
    buyhouseez: buyhouseez,
    minori: minori,
    "s republic": republic,
    "toni odisho": toni,
    toniandguy: toniandguy,
    "veronica tharmalingan": veronica,
  };
  return (
    <>
      <div className="flex flex-col gap-y-4 p-6 text-xl text-left   ">
        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-black py-5 font-thin">
          What the <span className="text-main-color">lab does</span>
        </h1>
        <div className="py-4 flex flex-row flex-wrap justify-evenly gap-2  text-lg md:text-xl lg:text-2xl">
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)]  lg:w-[calc((100%-64px)_/_4)] p-4">
            <h1 className="font-bold text-lg md:text-2xl lg:text-3xl pb-2 ">
              Websites
            </h1>
            <span className="flex flex-col justify-evenly  gap-y-2 ">
              <img src={Shopify} alt="Shopify" width={150} height={200} />
              <img src={WordPress} alt="WordPress" width={150} height={200} />
              <img src={Webflow} alt="Webflow" width={150} height={200} />
              <h4 className=" text-lg  md:text-xl  lg:text-3xl">
                and more ....
              </h4>
            </span>
          </div>
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)]  lg:w-[calc((100%-64px)_/_4)] p-4">
            <h1 className="font-bold text-lg md:text-2xl lg:text-3xl pb-2 ">
              Web Applications
            </h1>
            <p>↳ CUSTOM - the best way for a scalable solution.</p>
          </div>
          <div className="bg-[#f7f7f7]  w-[calc((100%-64px)_/_2)] lg:w-[calc((100%-64px)_/_4)] p-4">
            <h1 className="font-bold text-lg md:text-2xl lg:text-3xl pb-2 ">
              Mobile Applications
            </h1>
            <p>↳ CUSTOM - the best way for a scalable solution.</p>
          </div>
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)] lg:w-[calc((100%-64px)_/_4)] p-4">
            <p className="text-lg md:text-2xl lg:text-4xl font-bold">
              and <br /> much <br /> more…
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-xl  ">
        <h1 className="font-primary text-2xl  md:text-2xl lg:text-3xl text-black py-5 font-thin text-center">
          <span className="text-main-color">Experience </span>designers
        </h1>
        {Object.entries(imgs).map(([key, index]) => (
          <img
            src={index}
            alt={key}
            className=" w-full  lg:h-[34rem] font-primary  bg-cover bg-center mt-4"
          />
        ))}
      </div>
    </>
  );
};

export default Features;
