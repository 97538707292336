import { Routes, Route } from "react-router-dom";

import React from "react";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import HomeAgency from "../pages/HomeAgency";
import WebStories from "../pages/webStories";
import Advertising from "../pages/Advertising";
import SalesDev from "../pages/SalesDev";
import Growth from "../pages/Growth";
import SEO from "../pages/Seo";
import Success from "../pages/Success";
import HomeFactory from "../pages/HomeFactory";
import Accelerator from "../pages/Accelerator";
import SocialMedia from "../pages/Social Media";
import UGC from "../pages/UGC";
import HomeLab from "../pages/HomeLab";
import UXUIdesign from "../pages/UXUIdesign";
import WebDev from "../pages/webDev";
export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/accelerator" element={<Accelerator />} />
      <Route path="/agency" element={<HomeAgency />} />
      <Route path="/web-stories" element={<WebStories />} />
      <Route path="/advertising" element={<Advertising />} />
      <Route path="/sales-dev" element={<SalesDev />} />
      <Route path="/growth" element={<Growth />} />
      <Route path="/seo" element={<SEO />} />
      <Route path="/success" element={<Success />} />
      <Route path="/factory" element={<HomeFactory />} />
      <Route path="/social-media" element={<SocialMedia />} />
      <Route path="/ugc" element={<UGC />} />
      <Route path="/lab" element={<HomeLab />} />
      <Route path="/web-dev" element={<WebDev />} />
      <Route path="/ux-ui-design" element={<UXUIdesign />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

