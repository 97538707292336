import React from "react";
import Button from "../Button";
import {handleCheckout} from "./helpers"
import NoteForm from "../../components/Form/note";
const Index = () => {
 

  return (
    //
    <div className=" flex justify-center items-center bg-white my-4  text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          Ugc 🎥
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className=" text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
            Never
            <span className="text-main-color"> ad creatives </span> and
            <span className="text-main-color"> organic content</span> again.
          </p>
          <p className=" text-left font-secondary">
            Creating content that people actually want to see. Bring your brand
            to life through engaging, relevant and valuable content catered to
            your audience.
          </p>
          {/* <p className=" font-semibold font-primary ">Packages</p> */}
          {/* <div className="flex flex-row justify-between items-center gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
              6 videos
            </div>
            <div className="col-span-3">
              <Button
                type="button"
                id="primary"
                text1="$1,999/month"
                bgColor="bg-main-color"
                textColor="text-white"
                fontSize="text-base"
                onClick={() => handleCheckout(1999, " Ugc - 6 videos")}
              />
            </div>
          </div> */}
          {/* <div className="flex flex-row justify-between items-center gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
              12 videos
            </div>
            <div className="col-span-3">
              <Button
                type="button"
                id="primary"
                text1="$3,399/month"
                bgColor="bg-main-color"
                textColor="text-white"
                fontSize="text-base"
                onClick={() => handleCheckout(3399, " Ugc - 12 videos")}
              />
            </div>
          </div> */}
          {/* <div className="flex flex-row justify-between items-center gap-4">
            <div className="col-span-2  font-primary text-main-color ">
              18 videos
            </div>
            <div className="col-span-3">
              <Button
                type="button"
                id="primary"
                text1="$4,799/month"
                bgColor="bg-main-color"
                textColor="text-white"
                fontSize="text-base"
                onClick={() => handleCheckout(1000, " Ugc - 18 videos")}
              />
            </div>
          </div> */}

          <div className="grid grid-cols-12 bg-white pb-4  ">
            <div className="col-span-12 h-[1px] bg-black mb-2"></div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              What’s included?
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
              ☑  Collaborative workspace.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
              ☑  Vetted content creator.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
              ☑  Content script.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
              ☑  In-house editing.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
              ☑  &lt; 60 seconds videos.
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
          <NoteForm name="Ugc" />
        </div>
      </div>
    </div>
  );
};

export default Index;

