import React, { useEffect } from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SalesDev_Hero from "../components/Hero/salesDev_Hero";
import Form from "../components/Form";
import FeaturesSalesDev from "../components/Features/FeaturesSalesDev";
import advertising from "../assets/imags/_agency/businessDevelopment.png";
import SalesDevBox from "../components/Service plan box/sales dev";

const SalesDev = () => {
  useEffect(() => {
    document.title = "The Agency - Sales Dev. ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24 ">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl  "
          text="Developing your brand’s presence and sales on the territory  "
        />
        <SalesDev_Hero
          img={advertising}
          text={
            <>
              Cultivating{" "}
              <span class="text-main-color"> sales partnerships </span> is what
              we do best.
            </>
          }
        />
        <section className="">
          <FeaturesSalesDev />
          <div className="mb-10">
            <SalesDevBox />
          </div>
          <Form />
        </section>
        <Footer />
      </div>
    </>
  );
};

export default SalesDev;
