import React from "react";
import MerciHandyHandSanitizer from "../../assets/imags/Merci-Handy-Hand-Sanitizer.jpeg";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden shadow-lg border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit   font-bold text-lg md:text-2xl lg:text-4xl  mb-2">Merci Handy</div>
          <div className=" w-fit font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
            Sales dev. 👨🏻‍💼 + crm MKTG 👨🏻‍💻 + advertising 🚀
          </div>
        </div>

        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-center "
          src={MerciHandyHandSanitizer}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-2 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700 pb-10 ">
            Our team{" "}
            <span className="text-main-color font-extrabold">
              {" "}
              developed the brand’s overall activity{" "}
            </span>{" "}
            on the US market, leading the launch at major{" "}
            <span className="text-main-color font-extrabold ">
              {" "}
              retail partners{" "}
            </span>
            (Urban Outfitters, Free People, Anthropologie, Ulta Beauty, etc.) +
            adapt and develop the
            <span className="text-main-color font-extrabold">
              {" "}
              marketing strategy{" "}
            </span>{" "}
            + develop{" "}
            <span className="text-main-color font-extrabold">
              {" "}
              direct-to-consumer sales.
            </span>{" "}
            We also helped organized the setup of the local
            <span className="text-main-color font-extrabold">
              {" "}
              logistics{" "}
            </span>{" "}
            and
            <span className="text-main-color font-extrabold">
              {" "}
              customer service activities.
            </span>
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
            {" "}
            <span className="text-main-color font-extrabold">
              From $0 to $1.8M{" "}
            </span>{" "}
            in 2 years.
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            Direct-to-consumer increased by{" "}
            <span className="text-main-color font-extrabold"> 560%.</span>{" "}
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            From 0 to
            <span className="text-main-color font-extrabold">
              {" "}
              +750 locations{" "}
            </span>{" "}
            retail partners (Ulta Beauty, Urban Outfitters, Free People,
            Anthropology, etc.)
          </p>
        </div>
        <div className="bg-[#F7F7F7] py-8 px-4 m-2 lg:m-20">
          <p className=" font-secondary text-base md:text-lg lg:text-2xl font-thin italic text-center  leading-normal">
            Merci Handy offers clean 🌱 and out-of-the box <br />
            cosmetics essentials, made with rainbow extracts 🌈.
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
