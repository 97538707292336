import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Band from "../components/Band";
import Hero from "../components/Hero";
import HorizontalText from "../components/Horizontal Text";
import Form from "../components/Form";
import heroImg from "../assets/imags/the-lab-homepage.gif";
import Box from "../components/Box/Box";
import ServicePlan from "../components/Service plan box/indexLab";
import Bakery from "../components/Project/La belle French bakery";
import Pitch from "../components/Project/Pitch on air";
import TwistedPlants from "../components/Project/TwistedPlants";

import { NavLink } from "react-router-dom";
import Button from "../components/Button";
const Home = () => {
  useEffect(() => {
    document.title = "The Lab  - Home ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
   styleBand="bg-main-color py-4 px-4 sm:px-8 lg8	"
   textColor="text-font-color text-lg md:text-xl lg:text-2xl "
          text="Never run out of creatives and organic content - We develop a custom strategy,
           select quality creators, edit the assets, and deliver you weekly."
        />
        <Hero
          img={heroImg}
          text={
            <>
              Unlock the power of
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium mx-2">
                exceptional user experience.
              </span>
              User satisfaction is paramount in today’s digital landscape,
              that’s why at
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium mx-2">
                The LAB
              </span>
              we craft
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium mx-2">
                intuitive
              </span>
              and visually
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium mx-2">
                captivating interfaces
              </span>
              that leave a lasting impression.
            </>
          }
          btnText1="Let's  talk 💬"
        />

        <HorizontalText
          text={
            <>
              Leave behind the limitations of basic web design and development.
              we'll create an online presence that not only engages but also
              drives tangible results for your business. Let's collaborate for
              success!
            </>
          }
        />

      <section className="bg-gray-2 pb-10 pt-20  lg:pb-20 lg:pt-[30px] px-4 py-6 text-cente ">
          <div className="grid justify-start items-start gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <Box
              boxTitle="Web dev. 🤖"
              boxDescription="We bring together frontend, backend, and database technologies to ensure a consistent user experience across all platforms."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/web-dev"
            />
            <Box
              boxTitle="UX/UI/DESIGN 🎑 "
              boxDescription="We blend design thinking, user personas, and business objectives to craft user-centric journeys that ultimately drive sales up 🚀."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/ux-ui-design"
            />
          </div>
        </section> 

        <HorizontalText
          text={
            <>
              <span className="underline"> Projects </span> made with 🫶🏽 and
              delivering results for our clients. Projects made with 🫶🏽 and
              delivering results for our clients.
            </>
          }
        />
        <section className="w-full flex  flex-col gap-3  items-center pb-10 p-6">
          <Bakery />
          <TwistedPlants />
          <Pitch />
          <NavLink to="#" className="my-10">
            <Button
              type="button"
              id="primary"
              text1="See"
              text2="More"
              icon="👀"
              bgColor="bg-main-color lg:px-14 "
              textColor="text-white"
            />
          </NavLink>
        </section>
        <HorizontalText
          text={
            <>
              <span className="underline">Clear</span> and
              <span className="underline"> easy</span> pricing structure.
              no-hidden fees. No headaches 🤯.
            </>
          }
        />

        <section className="overflow-hidden">
          <ServicePlan />
        </section>
        <Form />
        <Footer />
      </div>
    </>
  );
};

export default Home;
