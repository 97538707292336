import React from "react";
import Button from "../Button";
import { NavLink } from "react-router-dom";
const index = ({ img, text, btnText1, btnText2, icon }) => {
  return (
    <header>
      <div className="bg-white px-4 py-6 text-cente  lg:text-left">
        <div className="w-100 mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="grid items-center justify-center gap-6 md:gap-12  md:grid-cols-2 lg:grid-cols-2 ">
            <div className="px-6  ">
              <p className=" leading-tight font-secondary mt-2  mb-8 text-lg lg:text-xl tracking-tight md:text-2xl xl:text-3xl [word-spacing:5px] ">
                {text}
              </p>
              <NavLink to="mailto:hello@fouronefour.co">
                <Button
                  type="button"
                  id="primary"
                  text1={btnText1}
                  text2={btnText2}
                  icon={icon}
                  bgColor="bg-main-color"
                  textColor="text-white"
                />{" "}
              </NavLink>
            </div>

            <div className="flex  justify-center items-center   order-1 sm:order-1 md:order-2 lg:order-2">
              <img
                src={img}
                className=" max-w-sm md:max-w-sm lg:max-w-md "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default index;
