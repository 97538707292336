import { useState, useRef, useEffect } from "react";
import Card from "./card";
import logo from "../../assets/imags/414.png";
import boss from "../../assets/imags/Julien L .png";
const Carousel = () => {
  const maxScrollWidth = useRef(0);
  const [startX, setStartX] = useState(0);
  const [deltaX, setDeltaX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const handleStart = (clientX) => {
    setStartX(clientX);
    setDeltaX(0);
    setIsSwiping(true);
  };

  const handleMove = (clientX) => {
    if (!isSwiping) return;

    const newDeltaX = clientX - startX;
    setDeltaX(newDeltaX);
  };

  const handleEnd = () => {
    if (!isSwiping) return;

    setIsSwiping(false);

    const childElement = carousel.current.children[currentIndex];

    if (childElement) {
      const advertisingWidth = childElement.offsetWidth;

      if (deltaX > advertisingWidth / 2) {
        movePrev();
      } else if (deltaX < -advertisingWidth / 2) {
        moveNext();
      }
    }
  };

  const handleTouchStart = (e) => {
    handleStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) {
      return;
    }

    const newDeltaX = e.touches[0].clientX - startX;
    setDeltaX(newDeltaX);

    const deltaX = Math.abs(newDeltaX);
    const deltaY = Math.abs(e.touches[0].clientY - startX);

    if (deltaX > deltaY) {
      e.preventDefault();
    }
  };

  const handleTouchEnd = () => {
    handleEnd();
  };

  const handleMouseDown = (e) => {
    handleStart(e.clientX);
  };

  const handleMouseMove = (e) => {
    handleMove(e.clientX);
  };

  const handleMouseUp = () => {
    handleEnd();
  };

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      const advertisingBoxWidth = carousel.current.offsetWidth;
      carousel.current.scrollLeft = advertisingBoxWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div
      className="carousel my-12 "
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={handleTouchEnd}
      onMouseDown={(e) => handleMouseDown(e)}
      onMouseMove={(e) => handleMouseMove(e)}
      onMouseUp={handleMouseUp}
    >
      <div className="text-4xl leading-8 font-semibold mb-12 text-slate-700">
        <div className="flex justify-end  gap-10  l h-full">
          <button
            onClick={movePrev}
            className="bg-main-color text-white w-10 h-full text-center opacity-75 opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled("prev")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-20 -ml-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">Prev</span>
          </button>
          <button
            onClick={moveNext}
            className="bg-main-color text-white w-10 h-full text-center  opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
            disabled={isDisabled("next")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-20 -ml-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
            <span className="sr-only">Next</span>
          </button>
        </div>
      </div>

      <div className="relative overflow-hidden">
        <div
          ref={carousel}
          className="carousel-container relative flex flex-nowrap flex-row gap-4  overflow-hidden scroll-smooth   z-0"
        >
          <div className="  box   ">
            <Card
              text={
                <>
                  <span className="text-white   pr-1 font-semibold">414</span>
                  is guiding brands in
                  <span className="text-white   px-1 font-semibold">
                    forging long-lasting bonds with their consumers
                  </span>
                  and leveraging innovative
                  <span className="text-white   px-1 font-semibold">
                    strategies, data and automation
                  </span>
                  to drive
                  <span className="text-white   px-1 font-semibold">
                    growth
                  </span>
                  both
                  <span className="text-white   px-1 font-semibold">
                    online
                  </span>
                  and
                  <span className="text-white   px-1 font-semibold">
                    offline.
                  </span>
                </>
              }
              img={logo}
            />
          </div>
          <div className="  box   ">
            <Card
              text1={
                <>
                  Leave ineffective, action-only
                  <span className=" underline"> marketing</span>
                </>
              }
              text2="in the rearview and let’s work together!"
              name={
                <>
                  Julien Lacomblez
                  <br />
                  Head of «the agency»
                </>
              }
              www="www.theagency.fouronefour.co"
              emailPhone={
                <>
                  (414) 554-1436 <br />
                  julien@fouronefour.co
                </>
              }
              img={boss}
            />
          </div>
          <div className="  box   ">
            <Card
              text1={
                <>
                  Leave ineffective, action-only
                  <span className=" underline"> marketing</span>
                </>
              }
              text2="in the rearview and let’s work together!"
              name={
                <>
                  Alex schwartz
                  <br />
                  Head of «the factory
                </>
              }
              www="www.thefactory.fouronefour.co"
              emailPhone={
                <>
                  (414) 554-1436
                  <br />
                  alex@fouronefour.co
                </>
              }
              img={logo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
