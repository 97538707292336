import React from "react";
import Button from "../Button";
import {handleCheckout} from "./helpers"
import NoteForm from "../../components/Form/note";

const index = () => {
  
  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          advertising 📈
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl  font-semibold font-primary">
            So that <span className="text-main-color"> every $ </span> invested
            <span className="text-main-color"> brings you</span> a little
            <span className="text-main-color"> more </span> each day.
          </p>
          <p className=" text-left font-secondary">
            We use a data-driven approach to experiment with various strategies,
            tactics, and channels to find what works best for driving growth.
          </p>
          {/* <p className=" font-semibold font-primary ">
            Packages available:{" "}
          </p> */}
          {/* <div className="grid grid-cols-5 justify-between gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
              Paid media
            </div>
            <div className="col-span-3 flex flex-col justify-end ">
              <Button
                type="button"
                id="primary"
                text1="10% Ad Budget"
                bgColor="bg-main-color "
                textColor="text-white"
                fontSize="text-base px-0 pl-2 pr-2"
                onClick={() => handleCheckout(1500 ,"Advertising")}                />
              <p className="text-[12px]  font-secondary">
                minimum of $1,500/month billed.
              </p>
            </div>
          </div> */}
          <div className="grid grid-cols-12 bg-white pb-4  ">
            <div className="col-span-12 h-[1px] bg-black mb-2"></div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              What’s included?
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Collaborative workspace.
            </div>

            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Weekly reports.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Audits (campaigns, landing pages, etc.).
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Ad Placement and Strategy.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Setup / test / optimization / repeat.
            </div>
          </div>      <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
            <NoteForm name="Advertising" />
        </div>
      </div>
    </div>
  );
};

export default index;
