import React from "react";
import Img1 from "../../assets/imags/Baguette.png";
import Img2 from "../../assets/imags/Butter croissant.png";
import Img3 from "../../assets/imags/Painauchocolat.png";
import Img4 from "../../assets/imags/IMG_7802.png";
import Img5 from "../../assets/imags/ad 1.jpg";
import Img6 from "../../assets/imags/ad 4.jpg";
import Img7 from "../../assets/imags/ad 3.jpg";
import Img8 from "../../assets/imags/ad 2.jpg";
import Img9 from "../../assets/imags/ad 7.jpg";
import Img10 from "../../assets/imags/ad 6.jpg";
import Img11 from "../../assets/imags/DSC08661.jpg";
import Img12 from "../../assets/imags/DSC05442.jpg";
import Img13 from "../../assets/imags/DSC_3433-1 (1).jpg";
import Img14 from "../../assets/imags/IMG_8527.png";
import Img15 from "../../assets/imags/IMG_9692_jpg.jpg";
import Img16 from "../../assets/imags/IMG_9668_jpg.jpg";
import Img17 from "../../assets/imags/IMG_9647_jpg.jpg";

import video4 from "../../assets/imags/Bag_1_Bench.mov";
import video1 from "../../assets/imags/VT_15s_4_H264.mov";
import video2 from "../../assets/imags/IMG_4187.mov";
import video3 from "../../assets/imags/VT_15s_3_H264.mov";
import Marquee from "react-fast-marquee";
import shuffle from "lodash/shuffle";
export const ImgBox = ({ img, alt }) => {
  return <img src={img} alt={img} className="boxImg " />;
};
export const VideoBox = ({ video }) => {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      src={video}
      type="video/mp4"
      className="boxImg1"
    />
  );
};

const Features = () => {
  const videos = {
    video1: video1,
    video2: video2,
    video3: video3,
    video4: video4,
  };

  const imgs = {
    Img1: Img1,
    Img2: Img2,
    Img3: Img3,
    Img4: Img4,
    Img5: Img5,
    Img6: Img6,
    Img7: Img7,
    Img8: Img8,
    Img9: Img9,
    Img10: Img10,
    Img11: Img11,
    Img12: Img12,
    Img13: Img13,
    Img14: Img14,
    Img15: Img15,
    Img16: Img16,
    Img17: Img17,
  };

  const shuffledVideos = shuffle(Object.entries(videos));
  const shuffledImgs = shuffle(Object.entries(imgs));

  return (
    <>
      <div className="flex flex-col items-center w-full ">
        <h1 className="font-primary w-full  p-6  text-2xl  md:text-2xl  lg:text-3xl text-black py-5 font-thin">
          WHAT DOES <span className="text-main-color"> The factory</span>
          approach LOOK LIKE?
        </h1>
        <div className="py-4 flex flex-row flex-wrap  justify-start p-6  lg:justify-evenly gap-2 text-lg md:text-xl lg:text-2xl">
          <div className="bg-[#f7f7f7] w-[calc((100%-32px)_/_2)]  lg:w-[calc((100%-64px)_/_3)] p-4 ">
            <span className="font-bold  text-xl md:text-2xl  lg:text-3xl px-1">
              +93%
            </span>
            of customers consult reviews and recommendations from real people
            before purchasing a product or service.
          </div>
          <div className="bg-[#f7f7f7] w-[calc((100%-32px)_/_2)]  lg:w-[calc((100%-64px)_/_3)] p-4">
            <span className="font-bold  text-xl md:text-2xl  lg:text-3xl px-1">
              10x
            </span>
            more views - UGC usage attracts more views. For instance, on
            YouTube, UGC videos receive 10x more views than brand-produced
            quality content.{" "}
          </div>
          <div className="bg-[#f7f7f7]  w-[calc((100%-32px)_/_2)] lg:w-[calc((100%-64px)_/_3)] p-4">
            <span className="font-bold  text-xl md:text-2xl  lg:text-3xl px-1">
              9.8x
            </span>
            more impactful - consumers find user-generated content 9.8x more
            impactful than influencer content.
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-xl text-left   ">
        <h1 className="font-primary  text-2xl  md:text-2xl  lg:text-3xl text-black py-5 font-thin">
          <span className="text-main-color"> Boosting </span>
          your own clients to create{" "}
          <span className="text-main-color"> ugc. </span>
        </h1>
        <dir className="font-secondary mt-5 ">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #1
            </span>
            <h2
              className={` font-semibold bg-[#f7f7f7] w-fit flex-grow  text-2xl  md:text-2xl  lg:text-3xl  p-4 `}
            >
              🗣️ Increase sales by giving your community a voice
            </h2>
          </div>
          <p className="pt-2 pl-4">
            Leverage consumer videos and customer testimonials to bolster your
            digital reputation and cred ibility, ultimately leading to
            heightened conversions and increased revenue.
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4 pl-0 ">
              #2
            </span>
            <h2
              className={`font-semibold bg-[#f7f7f7] w-fit flex-grow  text-2xl  md:text-2xl  lg:text-3xl  p-4 `}
            >
              🛒 Reinvent your online shopping experience
            </h2>
          </div>
          <p className="pt-2 pl-4">
            Get your community buzzing and positively impact customers by
            encouraging them to showcase their experiences with your products!
            When customers share their love through fun consumer videos on
            product pages and e-commerce sites, it not only boosts user
            experience but also automates the creation of awesome, large-scale
            videos. Let your customers shine and easily share their fantastic
            experiences!
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #3
            </span>
            <h2
              className={`font-semibold bg-[#f7f7f7] w-fit flex-grow  text-2xl  md:text-2xl  lg:text-3xl  p-4 `}
            >
              📣 Transform customers into brand ambassadors
            </h2>
          </div>
          <p className="pt-2 pl-4">
            In today’s consumer landscape, authenticity, transparency, and
            reassurance are key. And who better to spotlight the uses and
            benefits of your products and services than your own customers?
            Video content injects authenticity and spontaneity into your
            communications. Encouraging your community to jump on the
            video-making train not only cultivates content that aligns with your
            brand’s image and values but also turns customers into enthusiastic
            brand ambassadors.
          </p>
        </dir>
        <div className=" mt-12 ">
          <div className="carousel-container relative flex flex-nowrap flex-row gap-2  overflow-hidden scroll-smooth   z-0">
            <Marquee direction="left" speed={100} delay={2}>
              {shuffledVideos.map(([key, index]) => (
                <VideoBox video={index} key={key} />
              ))}
              {shuffledImgs.map(([key, index]) => (
                <ImgBox img={index} alt={key} key={key} />
              ))}
            </Marquee>
          </div>
        </div>
        <h1 className="font-primary  text-2xl  md:text-2xl  lg:text-3xl text-main-color mt-10">
          The <span className="text-black"> creative process</span>
        </h1>
        <div className={`bg-[#f7f7f7]`}>
          <p className={`  p-8 `}>
            <span className="font-bold"> 🤔 Creative Workshop: </span>
            Brainstorming with your team | Selection of creative concepts.
          </p>{" "}
        </div>
        <span className="text-center w-full text-5xl ">↓</span>
        <div className={`bg-[#f7f7f7]`}>
          <p className={`  p-8 `}>
            <span className="font-bold"> ✍️ Writing & Casting:</span>
            We conceive and write scripts | Selection of your creators and
            validation of their profiles.
          </p>
        </div>
        <span className="text-center w-full text-5xl ">↓</span>
        <div className={`bg-[#f7f7f7]`}>
          <p className={`  p-8 `}>
            <span className="font-bold"> 🤳 Shooting & Editing: </span>
            Selected creators produce content and make requested modifications.
          </p>
        </div>
        <span className="text-center w-full text-5xl ">↓</span>
        <div className={`bg-[#f7f7f7]`}>
          <p className={`  p-8 `}>
            <span className="font-bold"> 📊 Results & Analysis: </span>
            Together, we identify what worked best to focus on those aspects.{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Features;
