import React, { useEffect, useState } from "react";
import Content from "../../assets/imags/web-stories-content-creation.mp4";
import ecommerce from "../../assets/imags/web-stories-ecommerce.mp4";
import communication from "../../assets/imags/web-stories-internal-communication.mp4";
import application from "../../assets/imags/web-stories-mobile-application.mp4";
const Counter = ({ end, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime;
    const startCount = count;

    const updateCount = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const increment = (progress / duration) * (end - startCount);

      if (progress < duration) {
        setCount(startCount + increment);
        requestAnimationFrame(updateCount);
      } else {
        setCount(end);
      }
    };

    requestAnimationFrame(updateCount);
  }, [end, duration]);

  return <p className="w-full inline">{Math.ceil(count)}</p>;
};

const Features = () => {
  const [video, setVideo] = useState("Content");
  console.log("video", video);

  return (
    <>
      <div className="flex flex-col jus items-center w-full p-8">
        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-main-color w-full text-left">
          CONCLUSIVE RESULTS.
        </h1>
        <div id="projectFacts" className="w-full mt-5">
          <div className="w-full flex flex-wrap flex-row gap-4">
            <div className="bg-[#f7f7f7] py-10  p-10 pl-2 flex flex-col items-center text-lg md:text-xl lg:text-2xl w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className=" bg-[#f7f7f7] font-bold text-2xl md:text-3xl lg:text-4xl">
                +<Counter end={64} duration={2500} /> %
              </span>
              Conversion rate.
            </div>
            <div className=" bg-[#f7f7f7] py-10  p-10 pl-2 flex flex-col items-center text-lg md:text-xl lg:text-2xl w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className="font-bold text-2xl md:text-3xl lg:text-4xl">
                +<Counter end={26} duration={2500} /> %
              </span>
              Retention rate.
            </div>
            <div className=" bg-[#f7f7f7] py-10  p-10 pl-2 flex flex-col items-center text-lg   md:text-xl lg:text-2xl w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className="font-bold text-2xl md:text-3xl lg:text-4xl">
                +<Counter end={116} duration={2500} /> %
              </span>
              Session length.
            </div>

            <div className="bg-[#f7f7f7] py-10  p-10 pl-2 flex flex-col items-center text-lg md:text-xl lg:text-2xl w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className="font-bold text-2xl md:text-3xl lg:text-4xl">
                x<Counter end={4} duration={2500} />{" "}
              </span>
              Engagement rate.
            </div>
            <div className="bg-[#f7f7f7]  py-10  p-10 pl-2 flex flex-col items-center text-lg md:text-xl lg:text-2xl  w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className="font-bold text-2xl md:text-3xl lg:text-4xl">
                +<Counter end={31} duration={2500} /> %
              </span>
              Add to cart rate.
            </div>
            <div className=" bg-[#f7f7f7] py-10  p-10 pl-2 flex flex-col items-center  text-lg md:text-xl lg:text-2xl w-[calc((100%-48px)/2)] lg:w-[calc((100%-48px)/3)]">
              <span className="font-bold text-2xl md:text-3xl lg:text-4xl">
                -<Counter end={8} duration={2500} /> %
              </span>
              Bounce rate.
            </div>
          </div>
          <div className="pt-10 ">
            <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-main-color w-full text-left ">
              CONCLUSIVE RESULTS.
            </h1>
            <div className="bg-[#151315] flex flex-col md:flex-row justify-start items-center gap-x-5 mt-5 pt-5 md:pt-0 h-fit">
              <div className="w-full md:w-2/4 lg:w-2/6 lg:px-8 order-2 md:order-2">
                {video === "ecommerce" && (
                  <video
                    autostart
                    autoPlay
                    src={ecommerce}
                    type="video/mp4"
                    className="h-fit"
                  />
                )}
                {video == "communication" && (
                  <video
                    autostart
                    autoPlay
                    src={communication}
                    type="video/mp4"
                    className="h-fit"
                  />
                )}

                {video === "Content" && (
                  <video
                    autostart
                    autoPlay
                    src={Content}
                    type="video/mp4"
                    className="h-fit"
                  />
                )}
                {video === "application" && (
                  <video
                    autostart
                    autoPlay
                    src={application}
                    type="video/mp4"
                    className="h-fit"
                  />
                )}
              </div>

              <div className=" order-1 md:order-2 w-full md:w-2/4 flex flex-wrap justify-evenly flex-row  md:flex-col  md:justify-evenly items-center gap-2 md:items-start text-base  md:text-lg  lg:text-xl  gap-y-2 lg:gap-y-4 lg:pl-10">
                <button
                  className={`bg-[#f7f7f7] py-3  text-left  w-5/12 md:w-2/3 px-4 lg:px-10 cursor-pointer `}
                  onClick={() => setVideo("ecommerce")}
                >
                  🛒 E-commerce
                </button>
                <button
                  className={`bg-[#f7f7f7] py-3 px-3   text-left w-5/12 md:w-2/3 lg:px-10 cursor-pointer `}
                  onClick={() => setVideo("communication")}
                >
                  📣 Internal Communication
                </button>
                <button
                  className={`bg-[#f7f7f7] py-3 px-4   text-left w-5/12 md:w-2/3 lg:px-10  cursor-pointer`}
                  onClick={() => setVideo("Content")}
                >
                  🤳🏼 Content Marketing
                </button>
                <button
                  className={`bg-[#f7f7f7] py-3 px-4   text-left  w-5/12 md:w-2/3 lg:px-10 cursor-pointer  `}
                  onClick={() => {
                    setVideo("application");
                  }}
                >
                  📱 Mobile Application
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Features;
