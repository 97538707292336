import React from "react";
import bakery from "../../assets/imags/La belle French bakery.png";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden shadow-lg border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit  capitalize font-bold text-lg md:text-2xl lg:text-4xl  mb-2">
            La belle French bakery
          </div>
          <div className=" w-fit font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
            design 🎨
          </div>
        </div>

        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-center "
          src={bakery}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-4 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700 pb-10 ">
            Our team{" "}
            <span className="text-main-color font-extrabold">
              revamped the brand’s visual identity
            </span>{" "}
            as part of their fast growth in the Denver-area (Colorado). The new
            identity brings some
            <span className="text-main-color font-extrabold">freshness</span> to
            the brand while keeping the overall{" "}
            <span className="text-main-color font-extrabold">aesthetics</span>{" "}
            so particular to the French bakery history and recognition.
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
            {" "}
            <span className="text-main-color font-extrabold">
            New branding
            </span>{" "}
             for a Denver-area French bakery expanding quickly!
          </p>

        </div>
        <div className="bg-[#F7F7F7] py-8 px-4 m-2 lg:m-20">
          <p className=" font-secondary text-base md:text-lg lg:text-2xl font-thin italic text-center  leading-normal">
            La Belle French Bakery 🇫🇷 brings the flavor and <br />
            atmosphere of a traditional French bakery 🥖 🥐
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
