import React, { useState } from "react";
import Button from "../Button";
import { subscribe } from "klaviyo-subscribe";
import { useFormik } from "formik";
import { schema } from "./newsletter";
const Newsletter = () => {
  const [isloading, setIsloading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const onSubmit = async (values, helpers) => {
    const listId = process.env.REACT_APP_KLAVIYOID
    console.log(listId);
    setIsloading(true);
    subscribe(listId, values.email, {
      email: values.email,
    })
      .then((response) => {
        if(response.success) {
          setMessage("Success!");
          setIsloading(false);
          console.log("response", response);
          helpers.resetForm();
        }
        else
     {
      setIsloading(false);
      setError("There was an error, try again");
      console.log("error", error);
     }
      })
  
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        tandc: false,
      },
      validationSchema: schema,
      onSubmit,
    });

  return (
    <div className="bg-[#EFEFEF] w-full  text-font-color   space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
      <div className=" mx-auto grid lg:grid-cols-5 gap-2 md:gap-3">
        <div className="lg:col-span-3 my-4 pr-16 flex justify-center items-center">
          <p className="md:text-2xl sm:text-xl text-xl py-2 pl-2 md:pl-8 ">
            <span className="md:text-3xl font-bold    ">
              Sliding into your inbox{" "} 
            </span>
            our two cents on marketing and content creation 🤓 (in a saturated
            industry) in your inbox few times a month.
          </p>
        </div>
        <div className="my-4 lg:col-span-2 pr-2 md:pr-8">
          <div className=" w-full">
            <form onSubmit={handleSubmit}>
              <div className="w-full m-2 ml-0 flex">
                <input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required=""
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  placeholder="Your Email Address"
                  className={`block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-main-color  sm:text-lg sm:leading-6 ${
                    errors.email && touched.email
                      ? "border-l-8 border-red-600 focus:ring-0"
                      : ""
                  }`}
                />
                <Button
                  type="submit"
                  id="newsletter"
                  text1="Subscribe"
                  bgColor="bg-main-color"
                  textColor="text-white"
                  status={isloading}
                />
              </div>
              <input
                value={values.tandc}
                onChange={handleChange}
                onBlur={handleBlur}
                type="checkbox"
                name="tandc"
                id="tandc"
                className="inline mr-1 "
              />
              <p className="text-md inline ">
                I consent to the 
                <span className="text-font-color mx-1 underline lowercase text-lg ">
                terms and conditions
                </span> and
                <span className="text-font-color mx-1 underline lowercase  text-lg ">
                  Privacy Policy.
                </span>
              </p>
              <div className="mt-2 flex flex-col">
                {errors.email && touched.email && (
                  <p className="bg-red-50 p-2 mb-1 font-secondary font-semibold text-red-600  inline-block	">
                    {errors.email}
                  </p>
                )}
                {errors.tandc && (
                  <p className=" bg-red-50 p-2 font-secondary font-semibold text-red-600  inline-block	">
                    {errors.tandc}
                  </p>
                )}
                {error && (
                  <p className=" bg-red-50 p-2 font-secondary font-semibold text-red-600  inline-block	">
                    {error}
                  </p>
                )}

                {message && (
                  <div className="bg-green-50 p-4 font-secondary font-semibold">
                    {message && (
                      <p classNameName="block text-xl mt-4 text-green-600">
                        {message}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
