import React from "react";
import TONIGUY from "../../assets/imags/TONI&GUY USA.png";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden shadow-lg border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit   font-bold text-lg md:text-2xl lg:text-4xl  mb-2">
            TONI&GUY USA
          </div>
          <div className=" w-fit font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
            CRM MKTG 🧑‍💻 + ADVERTISING 🚀
          </div>
        </div>
        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-center "
          src={TONIGUY}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-2 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700 pb-10 ">
            After a difficult pandemic affecting the business,{" "}
            <span className="text-main-color font-extrabold"> TONI&GUY </span>{" "}
            USA needed to re-build a strong strategy to grow again. After a deep
            audit of the marketing tools and situation, I reorganized the
            company’s{" "}
            <span className="text-main-color font-extrabold"> tech stack </span>{" "}
            (saving +$7k/month) and developed a new strategy to{" "}
            <span className="text-main-color font-extrabold">
              {" "}
              revamp digital presence{" "}
            </span>{" "}
            and an integrated marketing strategy/operations to build
            <span className="text-main-color font-extrabold">
              {" "}
              growth.{" "}
            </span>{" "}
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
          
            <span className="text-main-color font-extrabold">
              Salons + Academy
            </span>{" "}
            revenue from{" "}
            <span className="text-main-color font-extrabold">
              $6.5M to $10M.
            </span>{" "}
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            Development of a
            <span className="text-main-color font-extrabold"> tech stack</span>{" "}
            and implementation of{" "}
            <span className="text-main-color font-extrabold"> AI</span> tools.
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            Development and implementation of a coherent
            <span className="text-main-color font-extrabold">
              {" "}
              marketing strategy.
            </span>{" "}
          </p>
        </div>
        <div className="bg-[#F7F7F7] py-8 px-4 m-2 lg:m-20">
          <p className=" font-secondary text-base md:text-lg lg:text-2xl font-thin italic text-center  leading-normal">
            {" "}
            TONI&GUY USA is an iconic superbrand in the hair and salon/academy
            industry.
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
