import React from "react";

const Features = () => {
  return (
    <>
      <div className="flex flex-col jus items-center w-full ">
        <div className="py-4 flex flex-row flex-wrap justify-evenly gap-2 text-lg md:text-xl lg:text-2xl">
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)]  lg:w-[calc((100%-64px)_/_4)] p-4">
            <span className="font-bold  text-xl  md:text-2xl  lg:text-3xl">
              68%{" "}
            </span>
            of all online experiences begin with a search engine.
          </div>
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)]  lg:w-[calc((100%-64px)_/_4)] p-4">
            <span className="font-bold  text-xl  md:text-2xl  lg:text-3xl">
              53%{" "}
            </span>{" "}
            of all website traffic comes from organic search.
          </div>
          <div className="bg-[#f7f7f7]  w-[calc((100%-64px)_/_2)] lg:w-[calc((100%-64px)_/_4)] p-4">
            <span className="font-bold text-xl  md:text-2xl  lg:text-3xl">
              0.63%
            </span>{" "}
            of Google searchers click on results from the 2nd page.
          </div>
          <div className="bg-[#f7f7f7] w-[calc((100%-64px)_/_2)] lg:w-[calc((100%-64px)_/_4)] p-4">
            <span className="font-bold  text-xl  md:text-2xl  lg:text-3xl">
              93%
            </span>{" "}
            of global traffic comes from Google Search, Google Images, and
            Google Maps.
          </div>
        </div>
      </div>
      <div className=" p-10 md:py-10 md:px-20 font-secondary text-lg md:text-xl ">
        <p className="mb-10">
          Does your content align with Google’s perception of relevance? Google
          looks for content that’s relevant to a search query in three important
          ways:
          <ul className=" pt-5 pl-8 list-[disclosure-closed]   ">
            <li> Topical relevance: Content topics matching search queries.</li>
            <li>
              {" "}
              Semantic relevance: The “meaning” of content matching search
              queries.{" "}
            </li>
            <li>
              Intent relevance: Content matching the user intent behind search
              queries.
            </li>
          </ul>
          <br />
          Google also considers content quality, user experience, and website
          authority when evaluating web pages.
        </p>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-xl text-left   ">
        <h1 className="font-primary  text-2xl  md:text-2xl  lg:text-3xl text-black py-5 font-thin">
          WHAT DOES <span className="text-main-color"> THE AGENCY </span>
          approach LOOK LIKE?
        </h1>
        <dir className="font-secondary mt-5 ">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #1
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              💸 Revenue Driven Strategy Build
            </h2>
          </div>
          <p className="pt-2 pl-4">
            We conduct a thorough analysis to align with your business goals and
            identify high-revenue SKUs. Our focus is on maximizing ROI, not just
            traffic. We perform an audit of existing pages, leveraging data from
            Google Search Console, Google Analytics, Ahrefs, and Screaming Frog
            to uncover immediate opportunities for short-term gains.
            Additionally, we conduct new keyword expansion to target potential
            page opportunities aligned with your business and revenue
            objectives. This involves identifying and prioritizing specific
            product categories based on user search terms. Finally, we
            prioritize the optimization of existing and new pages that align
            with your revenue goals, aiming to enhance visibility and
            conversions.
            <ul className=" pt-5 pl-8 list-disc ">
              <li>Deep dive into business goals and high-revenue SKUs.</li>
              <li> Audit existing pages for immediate opportunities. </li>
              <li>
                Expand keyword targets to uncover new potential page
                opportunities.
              </li>
              <li>
                {" "}
                Prioritize optimization for existing and new pages aligned with
                revenue goals.
              </li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4 pl-0 ">
              #2
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow  text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              🛠️ Website Restructuration
            </h2>
          </div>
          <p className="pt-2 pl-4">
            Once we identify your core product category, we seamlessly integrate
            our growth strategy by aligning UX and SEO. The optimization process
            involves enhancing navigation for an improved user journey,
            benefiting both sales and SEO. This includes strategically planning
            the main menu structure to avoid overwhelming customers with
            excessive links. We analyze customer behavior, considering factors
            like color, size, type, and material preferences. Simultaneously, we
            focus on restructuring category pages for SEO, incorporating
            additional content for Google analysis and internal links without
            compromising user experience or conversions.
            <ul className=" pt-5 pl-8 list-disc ">
              <li>Optimize navigation for improved user journey and SEO.</li>
              <li>
                Plan main menu structure strategically to avoid clutter and
                enhance SEO.{" "}
              </li>
              <li>
                Analyze customer behavior for effective category page
                structuring.
              </li>
              <li>
                Incorporate extra content and internal links on category pages
                for SEO without impacting conversions.
              </li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #3
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow   text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              ⚙️ Page Optimization
            </h2>
          </div>
          <p className="pt-2 pl-4">
            In executing the outlined SEO campaign strategy, we prioritize three
            key areas to capture maximum value potential:
            <ul className=" w-full pt-5 pl-8 list-disc ">
              <li>
                Establishing Technical SEO Foundations: A crucial step for
                future growth involves setting up solid technical SEO
                foundations. This phase ensures a clean website base, preventing
                any hindrance to future SEO efforts with thousands of pages.
              </li>
              <li>
                Optimizing Shopping Pages On-Page SEO: Focusing on high-priority
                product category pages, we optimize various SEO signals,
                including H1s, title tags, meta descriptions, subheadings,
                keyword density, internal linking, and competitor gap analysis.
                These changes are aligned with the top priority list to drive
                revenue swiftly.
              </li>
              <li>
                Adding Content Depth to Product Category Pages: To enhance the
                visibility and ranking of product category pages, we incorporate
                specific content aligned with the defined UX layout. This
                content boosts keyword coverage for both primary and secondary
                keywords, supporting the overall SEO strategy.
              </li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #4
            </span>
            <h2
              className={` bg-[#f7f7f7]  font-semibold w-fit flex-grow   text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              🚀 Scaling Reach
            </h2>
          </div>
          <p className="pt-2 pl-4">
            To elevate your brand’s growth, we propose creating sales-focused
            blog content that not only draws in additional customers to your
            sales funnel but also establishes your site as a topical authority
            in the eyes of Google. This, in turn, positively impacts the
            rankings of your «transactional» pages, such as category pages.
            <ul className=" w-full pt-5 pl-8 list-none">
              <li>
                a) Defining Content Strategy for SEO and ROI Alignment:
                <ul className=" pl-8 list-disc ">
                  <li>
                    Craft a content strategy prioritizing topics based on
                    conversion potential and SEO value for product category
                    pages.
                  </li>
                  <li>
                    Identify conversion-heavy topics related to your focused
                    products and targeting users near the bottom of the sales
                    funnel.
                  </li>
                  <li>
                    Prioritize Bottom of the Funnel (BOFU) search terms for
                    increased revenue.
                  </li>
                  <li>
                    Prioritize optimization for existing and new pages aligned
                    with revenue goals.
                  </li>
                  <li>Further prioritize topics based on SEO value.</li>
                </ul>
              </li>
              <li>
                b) Creating Conversion-Driven Blog Content to Increase Revenue:
                <ul className=" pl-8 list-disc ">
                  <li>
                    Avoid creating generic or irrelevant content that doesn't
                    bring a return on investment.
                  </li>
                  <li>
                    Ensure all content serves a specific purpose of guiding
                    users toward products and assisting your store and
                    customers.
                  </li>
                  <li>
                    Example: Fleur & Bee's skincare routine guide strategically
                    integrates their products, showcasing a perfect example of
                    conversion-driven content.
                  </li>
                  <li>
                    Emphasize Conversion Rate Optimization (CRO) to maximize
                    conversions from the content.
                  </li>
                </ul>
              </li>
              <li>
                c) Using the Blog as a Powerhouse for Category Pages SEO:
                <ul className=" pl-8 list-disc ">
                  <li>
                    Leverage the blog as a tool not only for sales assistance
                    but also for SEO benefits.
                  </li>
                  <li>
                    Each new article in your niche contributes to expanded
                    keyword coverage, establishing topical authority.{" "}
                  </li>
                  <li>
                    Boost rankings for your entire website, including blog pages
                    and category product pages.{" "}
                  </li>
                  <li>
                    Implement various SEO signals such as internal links to
                    enhance SEO juice for higher rankings.{" "}
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                In essence, this comprehensive approach combines strategic
                content creation, conversion focus, and SEO optimization to fuel
                your brand's growth.
              </li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4 pl-0 ">
              #5
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow   text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              🔗 Building Authority
            </h2>
          </div>
          <p className="pt-2 pl-4">
            To bolster specific target pages and elevate their rankings, a
            backlink audit suggests the need for additional links. These links,
            serving as votes of confidence, play a crucial role in achieving
            higher positions in the SERPs and tapping into the traffic
            associated with the top 3 positions.
            <ul className=" w-full pt-5 pl-8 list-none">
              <li>
                a) Dissecting Link Gaps with Competitors:
                <ul className=" pl-8 list-disc ">
                  <li>
                    Objective: Match competitor backlink profiles to close gaps.
                  </li>
                  <li>
                    Approach: Utilize SEO tools to analyze competitors’ links,
                    including the number, types, and anchor text used.
                  </li>
                  <li>
                    Mimic successful competitor links to create a natural and
                    competitive backlink profile.
                  </li>
                </ul>
              </li>
              <li>
                b) Breaching Link Gaps with Niche-Relevant Links:
                <ul className=" pl-8 list-disc ">
                  <li>
                    ᐧ Identify the missing link gap between your website and
                    competitors.
                  </li>
                  <li>
                    Outreach and replicate the exact same links to align with
                    your competitors' backlink SEO signals.
                  </li>
                  <li>
                    Achieve higher rankings by presenting a comparable backlink
                    profile to Google.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary  text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
              #6
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow  text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
              🧨 Rinse & Repeat
            </h2>
          </div>
          <p className="pt-2 pl-4">
            To elevate your store’s performance, we scale the existing strategy
            by exploring product range expansion, creating additional collection
            pages for improved rankings. We also focus on crafting sales-driven
            blog content to guide users through the sales funnel. Addressing any
            gaps in backlink authority, we prioritize building additional links
            to boost the visibility and ranking of target pages in the SERPs.
            <ul className=" w-full pt-5 pl-8 list-disc ">
              <li>
                Expand product range for more collection pages and enhanced
                rankings.
              </li>
              <li>
                Develop sales-oriented blog content to drive users through the
                sales funnel.
              </li>
              <li>
                Strengthen backlink authority through targeted link-building
                strategies for improved SERP rankings.
              </li>
            </ul>
          </p>
        </dir>
        <h1 className="font-primary text-2xl  md:text-2xl lg:text-3xl text-main-color mt-10 text-center">
          convinced <span className="text-black">you need to up your</span> SEO
          game?
        </h1>
      </div>
    </>
  );
};

export default Features;
