import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Band from "../components/Band";
import Hero from "../components/Hero";
import HorizontalText from "../components/Horizontal Text";
import Box from "../components/Box";
import AgencyImg from "../assets/imags/Agency.gif";
import LabImg from "../assets/imags/Lab.gif";
import FactoryImg from "../assets/imags/Factory.gif";
import Form from "../components/Form";
const Home = () => {
  useEffect(() => {
    document.title = "414-Home ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-base md:text-lg lg:text-xl "
          text="Real revenue-driving power begins with understanding your customers - knowing  who they are, thinking about what they want - and continues with data-informed strategies and genuine content."
        />
        <Hero
          text={
            <>
              <span className="text-main-color font-primary text-lg  md:text-3xl lg:text-4xl">
                414{" "}
              </span>
              is guiding brands in forging long-lasting bonds with their
              consumers and leveraging innovative strategies, data and
              automation to drive growth both online and offline.
            </>
          }
          btnText1="Let's  talk 💬"
        />
        <HorizontalText text="Leave ineffective, action-only marketing in the rearview and let’s work together!  Leave ineffective, action-only marketing in the rearview and let’s work together! " />

        <section className="flex flex-col  py-10">
          <Box
            img={AgencyImg}
            text={
              <>
                dedicated to propelling your brand towards{" "}
                <span className=" underline">rapid</span> rapid and enduring
                growth <span className=" underline">enduring{""}</span>{" "}
                <span className="underline">growth</span> while keeping costs in
                check.
              </>
            }
            title="The agency"
            link="/agency"
          />
          <Box
            img={FactoryImg}
            text={
              <>
                We’re here to <span className=" underline">drive</span>{" "}
                <span className=" underline">engagement</span> ,{" "}
                <span className=" underline">build trust</span>,{" "}
                <span className=" underline"> optimize visibility</span> and
                ultimately boost <span className=" underline">growth.</span>
              </>
            }
            title="The factory "
            link="/factory"
          />
          <Box
            img={LabImg}
            text={
              <>
                we craft <span className=" underline">intuitive</span> and{" "}
                <span className=" underline">
                  {" "}
                  visually captivating interfaces
                </span>{" "}
                that leave a lasting impression.
              </>
            }
            title=" The Lab"
            link="/lab"
          />
        </section>
        <Form />
        <Footer />
      </div>
    </>
  );
};

export default Home;
