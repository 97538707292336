import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Band from "../components/Band";
import Hero from "../components/Hero";
import astronaut from "../assets/imags/Agency.gif";
import HorizontalText from "../components/Horizontal Text";
import Grant from "../components/Project/grant";
import Workshop from "../components/Project/workshop";
import Accelerator from "../components/Project/accelerator";
import Form from "../components/Form";
import Application from "../components/Form/Application";
import Cards from "../components/Card";
const Home = () => {
  useEffect(() => {
    document.title = "414-Home ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-base md:text-lg lg:text-xl "
          text="Real revenue-driving power begins with understanding your customers - knowing  who they are, thinking about what they want - and continues with data-informed strategies and genuine content."
        />
        <Hero
          img={astronaut}
          text={
            <>
              Join us as we pave the way for dreams to become reality. We’re
              crafting a game-changing
              <span className="text-main-color font-primary text-lg  md:text-3xl lg:text-4xl px-2">
                fund
              </span>
              , not just about money, but
              <span className="text-main-color font-primary text-lg  md:text-3xl lg:text-4xl px-2">
                all that’s needed
              </span>
              , to fuel your passion and ambitions. 💪 <br />
              #dreamstofunds - #entrepreneurialrevolution
            </>
          }
          btnText1="Calling all Dreamers!"
        />
        <HorizontalText
          text="
      More than a simple fund - we build entire ecosystems of entrepreneurs eager to bring change in their communities. "
        />
        <section className="w-full flex  flex-col gap-3  items-center pb-10 p-6">
          <Workshop />
          <Accelerator />
          <Grant />
        </section>
        <HorizontalText
          text="
          Join a community of local entrepreneurs representing milwaukee"
        />
        <Application />
        <section className="">
          <Cards />
        </section>
        <Form />
        <Footer />
      </div>
    </>
  );
};

export default Home;
