const Loader = () => {


    return (
        <div id="spinner" className="load">
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
    );
};

export default Loader;