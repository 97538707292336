import React, { useState } from "react";
import Button from "../Button";
import { handleCheckout } from "./helpers";
import NoteForm from "../../components/Form/note";
const Index = () => {
  const [plan, setPlan] = useState("Part");

  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          Social media 📸
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
            Let us handle <span className="text-main-color"> part</span> or{" "}
            <span className="text-main-color"> the whole thing</span> for you.
          </p>
          <p className="text-left font-secondary ">
            We enjoy making better stories and changing the way they’re told.
            Our team supports you so you don’t have to hire multiple teams to
            see the magic happens.
          </p>
          {/* <p className="t font-semibold font-primary ">Packages available:</p> */}

          {/* <div className="flex flex-row justify-between items-center gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
              Part of it
            </div>
            <div className="col-span-3">
              <Button
                type="button"
                id="primary"
                text1="$1,500/month"
                bgColor="bg-main-color w-fit px-[5px] text-base md:text-lg lg:text-xl"
                textColor="text-white"
                onClick={() =>
                  handleCheckout(1500, " Social media  - Part of it")
                }
              />
            </div>
          </div> */}
          {/* <div className="flex flex-row justify-between items-center gap-4">
            <div className="col-span-2  font-primary text-main-color ">
              The whole thing
            </div>
            <div className="col-span-3">
              <Button
                type="button"
                id="primary"
                text1="$4,500/month"
                bgColor="bg-main-color w-fit px-[5px] text-base md:text-lg lg:text-xl"
                textColor="text-white"
                onClick={() =>
                  handleCheckout(4500, " Social media  - The whole thing")
                }
              />
            </div>
          </div> */}
          <div className="flex flex-row justify-between">
            <div
              className={`flex flex-col gap-y-1 ${
                plan !== "Part" ? "opacity-50" : ""
              }`}
            >
              <button
                className=" font-primary text-main-color  cursor-pointer"
                onClick={() => {
                  setPlan("Part");
                }}
              >
                Part of it
              </button>
            </div>
            <div
              className={`flex flex-col gap-y-1 ${
                plan !== "whole thing" ? "opacity-50" : ""
              }`}
            >
              <button
                className=" font-primary text-main-color  cursor-pointer"
                onClick={() => {
                  setPlan("whole thing");
                }}
              >
                The whole thing
              </button>
            </div>
          </div>
          {plan === "Part" && (
            <div className="grid grid-cols-12 bg-white pb-4  ">
              <div className="col-span-12 h-[1px] bg-black mb-2"></div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
                What’s included?
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Collaborative workspace
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2 ">
                ☑ Community management & posting
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Creative direction.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2 line-through text-gray-500">
                ☑ Content script.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2 line-through text-gray-500">
                ☑ In-house editing.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2 line-through text-gray-500">
                ☑ Up to 10 short videos UGC style.
              </div>
            </div>
          )}
          {plan === "whole thing" && (
            <div className="grid grid-cols-12 bg-white pb-4  ">
              <div className="col-span-12 h-[1px] bg-black mb-2"></div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
                What’s included?
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Collaborative workspace
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Community management & posting
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Creative direction.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Content script.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ In-house editing.
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                ☑ Up to 10 short videos UGC style.
              </div>
            </div>
          )}
          <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
          <NoteForm name="  Social media" />
        </div>
      </div>
    </div>
  );
};

export default Index;
