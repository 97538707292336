import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

export const handleCheckout = async (amount, name) => {
  console.log(
    "Stripe Publishable Key:",
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  try {
    const lineItems = [
      {
        price_data: {
          currency: "usd",
          product_data: {
            name: name,
          },
          unit_amount: amount * 100,
        },
        quantity: 1,
      },
    ];

    const response = await axios.post(
      "https://tiny-pear-bonobo-robe.cyclic.app/checkout",
      {
        lineItems,
      }
    );

    console.log(response);

    if (response && response.data) {
      const { data } = response;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.id });
    } else {
      console.error("Invalid response format:", response);
    }
  } catch (error) {
    console.error("Error during checkout:", error);
    throw error;
  }
};
