import React from "react";

export default function Band({ text , styleBand,textColor}) {
  return (
    <div className={`${styleBand}`}>
      <p className={`${textColor} font-primary text-center  leading-normal`	}>{text}</p>
    </div>
  );
}

