import React from "react";

const Features = () => {
  return (
    <>
      <div className="flex flex-col  items-center w-full p-8 ">
        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-main-color">
          Evaluate the acquisition costs for each channel and identify the
          audience that drives purchases.
        </h1>
        <div className="py-4 flex flex-col md:flex-row justify-evenly gap-2 lg:gap-10 text-lg md:text-xl lg:text-2xl">
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_4)]  p-4">
            <span className="font-bold">💰 Assess  </span>  your acquisition costs per channel and pinpoint the audience that converts.
          </div>
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_4)]  p-4">
            <span className="font-bold">💬 Uncover</span> which marketing messages resonate most with your audience.
          </div>
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_4)]   p-4">
            <span className="font-bold">🎯 Identifythe</span> the most responsive target audience.
          </div>
        </div>
        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-main-color py-5">
          <span className="text-black font-bold"> ↳ </span>OUR advertising
          campaigns foster growth beyond the scope of conventional advertising.
        </h1>
        <div className=" py-4 flex-wrap flex flex-row items-center gap-y-2 text-lg  md:text-xl ">
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Facebook Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Instagram Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            TikTok Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Pinterest Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Google Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Snapchat Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            YouTube Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            LinkedIn Ads
          </h3>
          <h3 className="w-[calc(100%_/_3)]  font-bold [text-shadow:-1px_1px_0px_#3b3b3b] ">
            Amazon Ads
          </h3>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-lg  md:text-xl  text-left font-secondary  ">
        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl  text-main-color py-5">
          For who? We got experts in
        </h1>
        <div className={`bg-[#f7f7f7] text-center`}>
          <p className={`   p-8 `}>
            <span className="font-bold"> 🛒 E-commerce: </span>
            Implement an advertising strategy to boost your sales on your
            e-commerce site.
          </p>
        </div>
        <div className={`bg-[#f7f7f7] text-center`}>
          <p className={`  p-8 `}>
            <span className="font-bold"> 🧲 Lead Generation: </span>
            Implement an online acquisition strategy to generate prospects for
            selling your B2C or B2B offer.
          </p>
        </div>
        <div className={`bg-[#f7f7f7] text-center`}>
          <p className={`  p-8`}>
            <span className="font-bold"> 🔑 SaaS Platform: </span>
            Implement an online acquisition strategy to generate sign-ups for a
            SaaS platform.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-xl text-left   ">
        <h1 className="font-primary text-2xl  md:text-2xl lg:text-3xl text-black py-5 font-thin">
          WHAT DOES <span className="text-main-color"> THE AGENCY </span>
          method look like?
        </h1>
        <dir className="font-secondary mt-5">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary text-4xl lg:text-6xl font-bold mr-4  pl-0 ">
              1 #
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow  text-2xl  md:text-2xl lg:text-3xl p-4 `}
            >
            💥 Kick-off Meeting
            </h2>
          </div>
          <p className="pt-2 pl-4">
          We initiate our iteration with a kick-off meeting between your team and ours to <span className=" underline"> collaboratively</span>  determine
           the marketing actions to be taken on a channel and set objectives. We analyze your environment, design your
            strategy, take control, and lay the foundations for the relationship:

            <ul className="flex flex-row w-full flex-wrap pt-5 pl-8 list-disc ">
              <li className="w-2/4"> Existing analysis</li>
              <li className="w-2/4">Competitor analysis </li>
              <li className="w-2/4">Ads design</li>
              <li className="w-2/4">Goal definition</li>
              <li className="w-2/4">Strategy creation</li>
            </ul>
          </p>
        </dir>
        <dir className="font-secondary mt-5 ">
          <div className={` flex flex-row justify-start items-center `}>
            <span className=" w-fit font-primary text-4xl lg:text-6xl font-bold mr-4  pl-0 ">
              2 #
            </span>
            <h2
              className={` bg-[#f7f7f7] font-semibold w-fit flex-grow text-2xl  md:text-2xl  lg:text-3xl p-4 `}
            >
             🧪 Experimentation
            </h2>
          </div>
          <p className="pt-2 pl-4">
          This is the pilot phase, lasting 4 weeks. During this cycle, we analyze your results daily and provide 
          a report at the beginning of each week. We test hypotheses that can drive growth, iterating on your
           initial acquisition channels, personas, and messages to acquire your first clients. Our tests include:

            <ul className="flex flex-row w-full flex-wrap pt-5 pl-8 list-disc ">
              <li className="w-2/4">Advertising platforms </li>
              <li className="w-2/4">ᐧVisuals and formats </li>
              <li className="w-2/4">Audiences</li>
              <li className="w-2/4">Message axes</li>
              <li className="w-2/4">Account structure</li>
            </ul>
          </p>

        </dir>
        <dir className="font-secondary mt-5">
            <div className={` flex flex-row justify-start items-center `}>
              <span className=" w-fit font-primary text-4xl lg:text-6xl  font-bold mr-4  pl-0 ">
                3 #
              </span>
              <h2
                className={` bg-[#f7f7f7] font-semibold  w-fit flex-grow  text-2xl  md:text-2xl lg:text-3xl p-4 `}
              >
               🎯 Growth
              </h2>
            </div>
            <p className="pt-2 pl-4">
            We accelerate your advertising investment to increase your revenue. We speed up your growth,
             broaden your pipeline, and explore new opportunities:
              <ul className="flex flex-row w-full flex-wrap pt-5 pl-8 list-disc ">
                <li className="w-2/4"> New channels</li>
                <li className="w-2/4">Segmentation  </li>
                <li className="w-2/4">Diversification</li>
                <li className="w-2/4"> Budget increase</li>
                <li className="w-2/4">New audiences</li>
              </ul>
            </p>
          </dir>
      </div>
    </>
  );
};

export default Features;
