import React from "react";
import NoteForm from "../../components/Form/note";
const index = () => {
  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          Sales dev. 👨🏻‍💼
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
            Cultivating{" "}
            <span className="text-main-color">Sales partnerships </span> is what
            we do best.
          </p>
          <p className=" text-left font-secondary">
            With an extensive portfolio of retail partnerships, spanning from
            niche boutiques and upscale spas to major big-box retailers, our
            team stands ready to bolster your business.
          </p>
          <p className="text-base text-left font-secondary mt-4">
            Since projects are all unique, we only operate on a quote based on
            your project.{" "}
          </p>

          <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
          <NoteForm name="Sales dev" />
        </div>
      </div>
    </div>
  );
};

export default index;
