import React from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UGCHero from "../components/Hero/SocialMedia_Hero";
import Form from "../components/Form";
import HorizontalText from "../components/Horizontal Text";
import FeaturesSocialMedia from "../components/Features/FeaturesSocialMedia";
import SocialMedia from "../assets/imags/socialMedia.png";
import SocialMediaBox from "../components/Service plan box/social media";
const SEO = () => {
  return (
    <>
      <div className=" container mx-auto  lg:px-24 overflow-hidden">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-xl  md:text-2xl  lg:text-3xl  "
          text="social media isn’t just a trend; it’s a powerhouse shaping today’s world. "
        />
        <UGCHero
          img={SocialMedia}
          text={
            <>
              Crafting social success <span class="text-main-color"> beyond trends. </span> 
            </>
          }
        />
        <section className="">
          <FeaturesSocialMedia />
          <HorizontalText text="Clear and easy pricing structure. no-hidden fees. No headaches 🤯. " />
          <div className="mb-10">
            <SocialMediaBox />
          </div>
          <Form />
        </section>
        <Footer />{" "}
      </div>
    </>
  );
};

export default SEO;
