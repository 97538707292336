import React from "react";
import Button from "../Button";
import events from "../../assets/imags/events.jpg";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden  border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit   font-bold text-lg md:text-2xl lg:text-4xl  mb-2">
            Name of program
          </div>
          <div className=" w-fit font-bold text-lg md:text-2xl lg:text-4xl text-main-color  mb-2 uppercase  text-right">
            workshop
          </div>
        </div>

        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-center "
          src={events}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-2 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700 pb-10 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget
            volutpat nunc. Curabitur placerat ante in mi convallis, vitae tempor
            metus interdum. Pellentesque habitant morbi tristique senectus et
            netus.
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            Curabitur placerat ante in mi convallis, vitae tempor metus
            interdum.
          </p>
          <p className="border-t-8 border-t-main-color py-1 mb-4 ">
            Ut lobortis, neque consectetur varius.
          </p>
          <Button
            type="button"
            id="primary"
            text1="Apply Now"
            bgColor="bg-main-color w-full"
            textColor="text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default index;
