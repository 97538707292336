import React from "react";
import { NavLink } from "react-router-dom";

import { FaInstagram, FaTiktok, FaFacebookF } from "react-icons/fa";
import { linksFooter, expore, information } from "../../assets/info";
import Newsletter from "../Newsletter";

const Footer = () => {
  return (
    <>
      <Newsletter />

      <footer className=" max-w-screen-xl font-secondary space-y-8 px-6 py-12 sm:px-6 lg:space-y-16  lg:px-8 mx-auto ">
        <div className="container mx-auto ">
          <div className="grid grid-cols-3 lg:grid-cols-5 gap-4  md:gap-8 border-t border-gray-100   lg:pt-16">
            <div className="col-span-3 md:col-span-3 lg:col-span-2 text-3xl 	">
              <p className="font-medium font-primary  text-gray-900 border-b-2 border-b-gray-500 w-fit	">
                The Agency <span className="text-base">by 414</span>
              </p>
              <ul className="mt-6 space-y-2 text-xl	 ">
                {/* <li className="font-primary">Address📍</li>
                <li>2612 S Greeley Street #120 Milwaukee, WI 53207</li> */}
                <li className="font-primary">Email 📧</li>
                <li>hello@fouronefour.co</li>
                <li className="font-primary">Phone 📱</li>
                <li>(414) 554-1436</li>
                <li className="font-primary">Website 👨🏻‍💻</li>
                <li>www.fouronefour.co</li>
              </ul>
              <div className="my-4 flex flex-row  gap-4  justify-center lg:justify-start">
                <NavLink
                  to="https://www.facebook.com/fouronefourmke?mibextid=LQQJ4d"
                  className="rounded-full  bg-black text-white text-base w-fit h-fit p-2 "
                >
                  <FaFacebookF color="white" size={15} className="" />
                </NavLink>
                <NavLink
                  to="https://www.instagram.com/fouronefour.mke?igsh=c3MydTU0bjVyYjA3"
                  className="rounded-full bg-black text-white text-base w-fit h-fit p-2 "
                >
                  <FaInstagram color="white" size={15} />
                </NavLink>
                <NavLink className="rounded-full bg-black text-white text-base w-fit h-fit p-2 ">
                  <FaTiktok color="white" size={15} />
                </NavLink>
              </div>
            </div>
            <div>
              <p className="font-medium text-gray-900 text-2xl underline uppercase">
                414
              </p>
              <ul className="mt-4 space-y-4 text-xl">
                {Object.entries(linksFooter).map(([key, index]) => (
                  <li key={key}>
                    <NavLink
                      to={`https://${key}.fouronefour.co`}
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {index}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="font-medium text-gray-900 text-2xl underline uppercase ">
                EXPLORE
              </p>
              <ul className="mt-6 space-y-4 text-xl">
                {Object.entries(expore).map(([key, index]) => (
                  <li key={key}>
                    <NavLink
                      to={`/${key}`}
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {index}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="font-medium text-gray-900 text-2xl underline uppercase">
                information
              </p>
              <ul className="mt-6 space-y-4 text-xl">
                {Object.entries(information).map(([key, index]) => (
           <li key={key}>
                    <NavLink
                      to={`${key}`}
                      className="text-gray-700 transition hover:opacity-75"
                    >
                      {index}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <p className="text-lg pt-8 font-bold text-font-color text-center ">
            Copyright &copy; 2024{" "}
            <span className="text-main-color"> 414 creative hub</span> ✌🏽 💙
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
