import React, { useState } from "react";
import logo from "../../assets/imags/414.png";
import { moblieLinksNav2 } from "../../assets/info";
import { NavLink } from "react-router-dom";
import Dropdown from "./dropdown";
import DropdownM from "./dropdown-m";
export const menuItems = [
  {
    title: "The Agency",
    route: "/agency",
    children: [
      {
        title: "advertising",
        route: "/advertising",
      },
      {
        title: "crm MKTG ",
        route: "/growth",
      },
      {
        title: "Web stories ",
        route: "/web-stories",
      },
      {
        title: "SEO ",
        route: "/seo",
      },
      {
        title: "Sales dev ",
        route: "/sales-dev",
      },
    ],
  },
  {
    title: "The Factory",
    route: "/factory",
    children: [
      {
        title: "ugC ",
        route: "/ugc",
      },
      {
        title: "Social media ",
        route: "/social-media",
      },
    ],
  },
  {
    title: "The Lab",
    route: "/lab",
    children: [
      {
        title: "UX/UI design",
        route: "/ux-ui-design",
      },
      {
        title: "Web dev ",
        route: "/web-dev",
      },
    ],
  },
  // {
  //   title: "The Hub",
  //   route: "https://thehub.fouronefour.co/",
  // },
];
export const Header = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <div className="font-primary uppercase sticky top-0 left-0 w-full z-30  bg-white text-font-color border-b">
        <nav className="container mx-auto px-4     ">
          <div className=" max-w-screen-xl flex flex-wrap items-center justify-between mx-auto  bg-white border-gray-200">
            <NavLink to="/">
              {" "}
              <img
                src={logo}
                alt="414 Hub"
                className="h-14 w-14  md:h-20 md:w-20 z-40  relative"
              />
            </NavLink>
            <ul className="hidden  lg:flex gap-4 justify-end items-center uppercase text-xl  ">
              {menuItems.map((item, index) => {
                return item.hasOwnProperty("children") ? (
                  <li key={index}>
                    <Dropdown item={item} route={item.route} />
                  </li>
                ) : (
                  <li key={index}>
                    <NavLink
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "#ffba5b",
                              background: "none",
                            }
                          : { color: "black", background: "none" }
                      }
                      className="p-4 transition hover:opacity-75"
                      to={item?.route || ""}
                    >
                      {item.title}
                    </NavLink>{" "}
                  </li>
                );
              })}

              {Object.entries(moblieLinksNav2).map(([key, index]) => (
                <li key={key}>
                  <NavLink
                    style={({ isActive }) =>
                      isActive
                        ? {
                            color: "#ffba5b",
                            background: "none",
                          }
                        : { color: "black", background: "none" }
                    }
                    to="mailto:hello@fouronefour.co"
                    className="p-4 transition hover:opacity-75"
                  >
                    {index}
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="block lg:hidden text-xl">
              <li onClick={handleNav} className="p-4">
                {nav ? "Close" : "Menu"}
              </li>
            </ul>
            <div
              className={`h-screen fixed  top-0 px-6 w-[50%] md:w-[30%]  border-r border-r-gray-200 bg-[#ffffff] ease-in duration-[1s] before:content('') before:top-0 before:left-0 before:w-screen before:h-scree ${
                nav ? "  left-0" : "left-[-100%]"
              }`}
            >
              <ul className="  grid columns-1 content-center h-screen w-full     ">
                {menuItems.map((item, index) => {
                  return item.hasOwnProperty("children") ? (
                    <li key={index} className="p-4 border-b border-gray-600">
                      <DropdownM item={item} route={item.route} />
                    </li>
                  ) : (
                    <li key={index} className="p-4 border-b border-gray-600">
                      <NavLink
                        className="p-4 transition hover:opacity-75"
                        to={item?.route || ""}
                      >
                        {item.title}
                      </NavLink>{" "}
                    </li>
                  );
                })}
                {Object.entries(moblieLinksNav2).map(([key, index]) => (
                  <li key={key} className="px-2 py-4 border-b border-gray-600">
                    <NavLink
                      to="mailto:hello@fouronefour.co"
                      className="p-4 transition hover:opacity-75"
                    >
                      {index}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
