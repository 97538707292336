import React ,{useEffect} from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AdvertisingHero from "../components/Hero/advertising_Hero";
import Form from "../components/Form";
import HorizontalText from "../components/Horizontal Text";
import FeaturesAdvertising from "../components/Features/FeaturesAdvertising";
import advertising from "../assets/imags/_agency/advertising.jpg";
import AdvertisingBox from "../components/Service plan box/advertising";
const Advertising = () => {

  
  useEffect(() => {
    document.title = "The Agency - Advertising ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24 ">
        <Header />

        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-xl  md:text-2xl  lg:text-3xl "
          text="We handle your acquisition campaigns to propel your online growth 📈  "
        />
        <AdvertisingHero
          img={advertising}
          text={
            <>
              {" "}
              Your <span class="text-main-color">Social Ads campaigns</span> are
              NOT reaching their full potential.{" "}
            </>
          }
        />
        <section className=" ">
          <FeaturesAdvertising />
          <HorizontalText
            text={
              <>
                <span className="underline">Clear</span> and
                <span className="underline"> easy</span> pricing structure.
                no-hidden fees. No headaches 🤯.
              </>
            }
          />{" "}
          <div className="mb-10">
            <AdvertisingBox />
          </div>
          <Form />
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Advertising;
