import React from "react";

const index = ({ text, img, text1, text2, www, name, emailPhone }) => {
  return (
    <div className="max-w-sm p-4">
      <div className=" bg-white flex flex-col justify-center items-center shadow-lg  overflow-hidden">
        <div className="relative pt-10 flex justify-center items-center  w-[400px]">
          <div id="sidefirst" className="  w-[200px] h-[200px]  ">
            <img src={img} alt="logo" className="w-full h-full" />
          </div>
          {text1 && (
            <p
              id="side"
              className="text-sm  md:text-sm lg:text-base font-primary   absolute bottom-[109px] z-10 right-[-51px] transform  rotate-90 "
            >
              {text1}
            </p>
          )}
        </div>
        <div className="bg-main-color w-full flex  flex-col justify-center items-center p-8 h-[240px] relative pt-10 ">
          {text && (
            <p className="leading-tight font-secondary mt-2  mb-8 tracking-tight text-lg  md:text-xl lg:text-2xl pr-4">
              {text}
            </p>
          )}

          {name && (
            <h1 className="text-center text-lg  md:text-xl lg:text-2xl text-white font-primary font-semibold">
              {name}
            </h1>
          )}

          {emailPhone && (
            <h1 className="text-center text-lg  md:text-xl lg:text-2xl text-black font-secondary">
              {emailPhone}
            </h1>
          )}
          {www && (
            <h1 className="text-center text-lg  md:text-xl lg:text-2xl text-white font-secondary font-semibold">
              {www}
            </h1>
          )}
          {text2 && (
            <p className=" text-sm  md:text-sm lg:text-base font-primary absolute top-[107px] z-10 right-[-75px] transform  rotate-90 text-white">
              {text2}{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default index;
