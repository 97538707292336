import React from "react";
import Button from "../Button";
import { handleCheckout } from "./helpers";
import NoteForm from "../../components/Form/note";

import { useState } from "react";
const Index = () => {
  const [action, setAction] = useState("Starter");

  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          Web stories 🤳🏼
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
            The social proof to <span className="text-main-color"> boost </span>{" "}
            your e-commerce
            <span className="text-main-color"> sales </span> and{" "}
            <span className="text-main-color"> seo </span> efforts!
          </p>
          <p className=" text-left font-secondary">
            We make it easy for you to engage your audience with Web Stories. On
            mobile and desktop, they correspond to the new consumption habits of
            Internet users.
          </p>
          {/* <p className=" font-semibold font-primary ">
            Packages available:{" "}
          </p> */}

          {/* <div className="grid grid-cols-5 justify-between gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
            Starter
            </div>
            <div className="col-span-3 flex flex-col justify-end">
            <Button
                type="button"
                id="primary"
                text1="$300/Month"
                bgColor="bg-main-color "
                textColor="text-white"
                fontSize="text-base"
                onClick={() => handleCheckout(300, " Web Stories - Starter")}
              />
            </div>
          </div> */}
          {/* <div className="grid grid-cols-5 justify-between gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
            Pro
            </div>
            <div className="col-span-3 flex flex-col justify-end w-full">
        <Button
                type="button"
                id="primary"
                text1="$500/Month"
                bgColor="bg-main-color "
                textColor="text-white"
                fontSize="text-base px-0 pl-2 pr-2"
                onClick={() => handleCheckout(500, " Web Stories - Pro")}
              />
          
            </div>
          </div> */}
          {/* <div className="grid grid-cols-5 justify-between gap-4">
            <div className="col-span-2  font-primary text-main-color  ">
                   Enterprise
            </div>
            <div className="col-span-3 flex flex-col justify-end">
            <Button
                type="button"
                id="primary"
                text1="$1,000/Month"
                bgColor="bg-main-color "
                textColor="text-white"
                fontSize="text-base"
                onClick={() =>
                  handleCheckout(1000, " Web Stories - Enterprise")
                }
              />
            </div>
          </div> */}
          <div className="flex flex-row justify-between">
            <div
              className={`flex flex-col gap-y-1 ${
                action !== "Starter" ? "opacity-50" : ""
              }`}
            >
              <button
                className=" font-primary text-main-color  cursor-pointer"
                onClick={() => {
                  setAction("Starter");
                }}
              >
                Starter
              </button>
    
            </div>
            <div
              className={`flex flex-col gap-y-1 ${
                action !== "Pro" ? "opacity-50" : ""
              }`}
            >
              <button
                className=" font-primary text-main-color  cursor-pointer"
                onClick={() => {
                  setAction("Pro");
                }}
              >
                Pro
              </button>
      
            </div>
            <div
              className={`flex flex-col gap-y-1 ${
                action !== "Enterprise" ? "opacity-50" : ""
              }`}
            >
              <button
                className=" font-primary text-main-color  cursor-pointer"
                onClick={() => {
                  setAction("Enterprise");
                }}
              >
                Enterprise
              </button>

            </div>
          </div>

          {action === "Starter" && (
            <div className="grid grid-cols-12 bg-white pb-4  ">
              <div className="col-span-12 h-[1px] bg-black mb-2"></div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
                What’s included?
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  "> ☑ General </span>{" "}
                <br />
                Unlimited number of stories 20 widget integrations URL, email,
                QR-code, SMS 1 workspace - 1 user
              </div>

              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Engagement functionality
                </span>
                <br />
                Swipe-up & button
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold ">
                  {" "}
                  ☑ Design functionality
                </span>
                <br />
                Gallery of +120 models
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Analytical functionality
                </span>
                <br />
                Basic view
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Customer service & support
                </span>
                <br /> Help center, chat & email
              </div>
            </div>
          )}
          {action === "Pro" && (
            <div className="grid grid-cols-12 bg-white pb-4  ">
              <div className="col-span-12 h-[1px] bg-black mb-2"></div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
                What’s included?
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  "> ☑ General </span>{" "}
                <br />
                Unlimited number of stories Unlimited widget integrations URL,
                email, QR-code, SMS 1 workspace - 3 users
              </div>

              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold ">
                  {" "}
                  ☑ Engagement functionality
                </span>
                <br />
                Swipe-up & button , Connect to Instagram , Quizz + Survey
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Design functionality
                </span>
                <br />
                Gallery of +120 models , 1 personalized font , 1 custom-made
                model
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold ">
                  {" "}
                  ☑ Analytical functionality
                </span>
                <br />
                Basic view
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Customer service & support
                </span>
                <br />
                Help center, chat & email , Customer success support
              </div>
            </div>
          )}
          {action === "Enterprise" && (
            <div className="grid grid-cols-12 bg-white pb-4   ">
              <div className="col-span-12 h-[1px] bg-black mb-2"></div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
                What’s included?
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  "> ☑ General </span>{" "}
                <br />
                Unlimited number of stories Unlimited widget integrations URL,
                email, QR-code, SMS 1 workspace - 5 users
              </div>

              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Engagement functionality
                </span>
                <br />
                Swipe-up & button , Connect to Instagram , Quizz + Survey , Lead
                gen. form
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Design functionality
                </span>
                <br />
                Gallery of +120 models , Custom fonts 5 ,custom-made models ,
                Advanced video features
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Analytical functionality
                </span>
                <br />
                Advanced view
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Customer service & support
                </span>
                <br /> Help center, chat & email Customer success support ,
                Dedicated account manager
              </div>
              <div className="col-span-12 text-base md:text-lg lg:text-xl  items-center font-light pl-2">
                <span className="font-extrabold  ">
                  {" "}
                  ☑ Security feature
                </span>
                <br /> Double authentication
              </div>
            </div>
          )}


<div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
            <NoteForm name="Web stories" />
        </div>      
    

      </div>
    </div>
  );
};

export default Index;
