import React from "react";
import Button from "../Button";
import { NavLink } from "react-router-dom";
const Box = ({ boxTitle, boxDescription, btnText1, btnText2, icon, path }) => {
  return (
    <>
      <div className="mb-10 overflow-hidden  bg-gray-100   ">
        <div className="p-8 text-left sm:p-9 md:p-7 xl:p-9  ">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-primary pb-4">
            {boxTitle}
          </h1>
          <p className="mb-7  text-lg md:text-xl lg:text-2xl leading-relaxed text-font-color ">
            {boxDescription}
          </p>
          <NavLink to={path}>
            <Button
              type="button"
              id="primary"
              text1={btnText1}
              text2={btnText2}
              icon={icon}
              bgColor="bg-main-color  "
              textColor="text-white"
            />
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Box;
