import React from "react";
import Img1 from "../../assets/imags/Baguette.png";
import Img2 from "../../assets/imags/Butter croissant.png";
import Img3 from "../../assets/imags/Painauchocolat.png";
import Img4 from "../../assets/imags/IMG_7802.png";
import Img5 from "../../assets/imags/ad 1.jpg";
import Img6 from "../../assets/imags/ad 4.jpg";
import Img7 from "../../assets/imags/ad 3.jpg";
import Img8 from "../../assets/imags/ad 2.jpg";
import Img9 from "../../assets/imags/ad 7.jpg";
import Img10 from "../../assets/imags/ad 6.jpg";
import Img11 from "../../assets/imags/DSC08661.jpg";
import Img12 from "../../assets/imags/DSC05442.jpg";
import Img13 from "../../assets/imags/DSC_3433-1 (1).jpg";
import Img14 from "../../assets/imags/IMG_8527.png";
import Img15 from "../../assets/imags/IMG_9692_jpg.jpg";
import Img16 from "../../assets/imags/IMG_9668_jpg.jpg";
import Img17 from "../../assets/imags/IMG_9647_jpg.jpg";

import video4 from "../../assets/imags/Bag_1_Bench.mov";
import video1 from "../../assets/imags/VT_15s_4_H264.mov";
import video2 from "../../assets/imags/IMG_4187.mov";
import video3 from "../../assets/imags/VT_15s_3_H264.mov";

import facebook from "../../assets/imags/_factory/facebook.svg";
import instagram from "../../assets/imags/_factory/instagram.svg";
import linkedin from "../../assets/imags/_factory/linkedin.svg";
import pinterest from "../../assets/imags/_factory/pinterest.svg";
import snapchat from "../../assets/imags/_factory/snapchat.svg";
import tiktok from "../../assets/imags/_factory/tiktok.svg";
import shuffle from "lodash/shuffle";

const icons = {
  facebook: facebook,
  instagram: instagram,
  linkedin: linkedin,
  pinterest: pinterest,
  snapchat: snapchat,
  tiktok: tiktok,
};
const videos = {
  video1: video1,
  video2: video2,
  video3: video3,
  video4: video4,
};

const imgs = {
  Img1: Img1,
  Img2: Img2,
  Img3: Img3,
  Img4: Img4,
  Img5: Img5,
  Img6: Img6,
  Img7: Img7,
  Img8: Img8,
  Img9: Img9,
  Img10: Img10,
  Img11: Img11,
  Img12: Img12,
  Img13: Img13,
  Img14: Img14,
  Img15: Img15,
  Img16: Img16,
  Img17: Img17,
};
const shuffledVideos = shuffle(Object.entries(videos));
const shuffledImgs = shuffle(Object.entries(imgs));
export const IconSocialMedia = ({ img, alt }) => {
  return (
    <img
      src={img}
      alt={img}
      className="w-[34px] h-[34px] lg:h-[54px] lg:w-[54px] "
    />
  );
};

export const ImgBox = ({ img, alt }) => {
  return <img src={img} alt={img} className="boxImg " />;
};
export const VideoBox = ({ video }) => {
  return (
    <video
    autoPlay
    loop
    muted
    playsInline
      src={video}
      type="video/mp4"
      className="boxImg"
    />
  );
};

const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 mb-32 ">
      <div className=" overflow-hidden  border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit text-main-color font-primary font-bold text-xl md:text-3xl lg:text-5xl  mb-2">
            All
          </div>
          <div className=" w-fit flex justify-evenly gap-x-6 lg:gap-x-12 font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
            {Object.entries(icons).map(([key, index]) => (
              <IconSocialMedia img={index} alt={key} key={key} />
            ))}
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 w-full h-auto mt-6">
          {shuffledVideos.map(([key, index]) => (
            <VideoBox video={index} key={key} />
          ))}
          {shuffledImgs.map(([key, index]) => (
            <ImgBox img={index} alt={key} key={key} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default index;
