import React from "react";
import Img1 from "../../assets/imags/Baguette.png";
import Img2 from "../../assets/imags/Butter croissant.png";
import Img3 from "../../assets/imags/Painauchocolat.png";
import Img4 from "../../assets/imags/IMG_7802.png";
import Img5 from "../../assets/imags/ad 1.jpg";
import Img6 from "../../assets/imags/ad 4.jpg";
import Img7 from "../../assets/imags/ad 3.jpg";
import Img8 from "../../assets/imags/ad 2.jpg";
import Img9 from "../../assets/imags/ad 7.jpg";
import Img10 from "../../assets/imags/ad 6.jpg";
import Img11 from "../../assets/imags/DSC08661.jpg";
import Img12 from "../../assets/imags/DSC05442.jpg";
import Img13 from "../../assets/imags/DSC_3433-1 (1).jpg";
import Img14 from "../../assets/imags/IMG_8527.png";
import Img15 from "../../assets/imags/IMG_9692_jpg.jpg";
import Img16 from "../../assets/imags/IMG_9668_jpg.jpg";
import Img17 from "../../assets/imags/IMG_9647_jpg.jpg";

import video4 from "../../assets/imags/Bag_1_Bench.mov";
import video1 from "../../assets/imags/VT_15s_4_H264.mov";
import video2 from "../../assets/imags/IMG_4187.mov";
import video3 from "../../assets/imags/VT_15s_3_H264.mov";
import Marquee from "react-fast-marquee";
import shuffle from "lodash/shuffle";
export const ImgBox = ({ img, alt }) => {
  return <img src={img} alt={img} className="boxImg " />;
};
export const VideoBox = ({ video }) => {
  return (
    <video
      autoPlay
      loop
      muted
      playsInline
      src={video}
      type="video/mp4"
      className="boxImg1"
    />
  );
};

const Features = () => {
  const videos = {
    video1: video1,
    video2: video2,
    video3: video3,
    video4: video4,
  };

  const imgs = {
    Img1: Img1,
    Img2: Img2,
    Img3: Img3,
    Img4: Img4,
    Img5: Img5,
    Img6: Img6,
    Img7: Img7,
    Img8: Img8,
    Img9: Img9,
    Img10: Img10,
    Img11: Img11,
    Img12: Img12,
    Img13: Img13,
    Img14: Img14,
    Img15: Img15,
    Img16: Img16,
    Img17: Img17,
  };

  const shuffledVideos = shuffle(Object.entries(videos));
  const shuffledImgs = shuffle(Object.entries(imgs));
  return (
    <>
      <div className="flex flex-col  items-center w-full text-lg md:text-xl lg:text-2xl ">
        <h1 className="font-primary w-full  p-6  text-2xl  md:text-2xl lg:text-3xl text-black py-5 font-thin">
          Why go <span className="text-main-color"> social? </span>
        </h1>
        <div className="py-4 p-6  flex flex-row flex-wrap justify-evenly gap-2 ">
          <p>
            Once upon a time, a killer website and
            <span className=" underline"> SEO strategy </span> were all you
            needed online. Fast forward to today, and social media is the
            undisputed ruler of the digital realm. No matter your target
            audience, from Gen Z to Boomers, they’re on social media, and that’s
            where you’ll catch their attention. <br /> <br /> It’s not just a
            platform for sharing personal stories; it’s a hub for discovering
            new products and services, making it an indispensable tool for any
            business looking to thrive in the digital age.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 p-6 text-xl text-left   ">
        <div className=" mt-12 ">
          <div className="carousel-container relative flex flex-nowrap flex-row gap-2  overflow-hidden scroll-smooth   z-0">
            <Marquee direction="left" speed={100} delay={2}>
              {shuffledVideos.map(([key, index]) => (
                <VideoBox video={index}  key={key}/>
              ))}
              {shuffledImgs.map(([key, index]) => (
                <ImgBox img={index} alt={key}  key={key}/>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
      <div className="flex flex-col  items-center w-full text-lg md:text-xl lg:text-2xl  ">
        <h1 className="font-primary w-full text-2xl  md:text-2xl lg:text-3xl p-6   text-black py-5 font-thin">
          A Social Media <span className="text-main-color"> makeover </span>
        </h1>
        <div className="py-4  p-6  flex flex-row flex-wrap justify-evenly gap-2 ">
          <p>
            Don’t miss the social media wave! Whether it’s Facebook/Instagram,
            TikTok, YouTube or Snapchat (among others), these platforms offer a
            golden opportunity for businesses to boost exposure and garner more
            interest in their brand. Yet, many businesses, when they come to us,
            are either neglecting social networks or struggling with an
            inconsistent approach.
            <br />
            <br />
            How can we help you make the most of your social media investment?
            We dive deep into understanding your business, conducting a
            comprehensive audit, and revamping your strategy. From crafting
            audience personas to strategic ad deployment, we tailor a plan
            specific to your brand.
            <br />
            <br />
            Our entrepreneurial outlook, backed by decades of collective
            experience, sets us apart. We don’t just see it as Social Media
            Management; we see it as a partnership in your business success.
            Let’s chat and explore how we can propel your business to new
            heights! 🚀
          </p>
        </div>
      </div>
    </>
  );
};

export default Features;
