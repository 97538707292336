import React, { useEffect } from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WebDev_Hero from "../components/Hero/WebDev_Hero";
import Form from "../components/Form";
import FeaturesWebDev from "../components/Features/FeaturesWebDev";
import advertising from "../assets/imags/_lab/web.jpg";
import WebDevBox from "../components/Service plan box/webDev";
import HorizontalText from "../components/Horizontal Text";
const WebDev = () => {
  useEffect(() => {
    document.title = "The Lab - Web Dev.  ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg8	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl "
          text="Guaranteeing user experience is in adequation with web performance. "
        />
        <WebDev_Hero
          img={advertising}
          text={
            <>
              Coding the <span class="text-main-color"> optimal </span> digital{" "}
              <span class="text-main-color"> experience. </span>
            </>
          }
        />
        <section className="">
          <FeaturesWebDev />
          <HorizontalText
            text={
              <>
                <span className="underline">Clear</span> and
                <span className="underline"> easy</span> pricing structure.
                no-hidden fees. No headaches 🤯.
              </>
            }
          />
          <div className="">
            <WebDevBox />
          </div>
          <Form />
        </section>
        <Footer />
      </div>
    </>
  );
};

export default WebDev;
