export const linksFooter = {
    "theagency" :"The Agency",
    "thefactory" :"The Factory",
    "thelab":"The Lab ",
    // "thehub":"The Hub"
};
export const expore = {
    // "shop" :"The Shop",
    // "co-Lab":"The Co-Lab",
    // "terms&conditions":"Terms & conditions" ,
    // "privacy-policy":"Privacy Policy",
    "":"Terms & conditions" ,
    "#":"Privacy Policy"
};

export const information = {
    "mailto:hello@fouronefour.co" :"Contact us",
    "tel:4145541436" :"Call us",

};

export const moblieLinksNav1 = {
    "theagency" :"The Agency",
    "thefactory" :"The Factory",
    "thelab":"The Lab ",
    "thehub":"The Hub",
    "theshop" :"The Shop",
};
export const moblieLinksNav2 = {

    // "co-Lab":"The Co-Lab",
    "contact-us" :"Contact us",
};
