import React, { useState } from "react";
import Button from "../Button";
import { useFormik } from "formik";
import { noteShema } from "./contactShema";
import emailjs from "@emailjs/browser";

const Index = ({ name }) => {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const onSubmit = async (values, helpers) => {
    setLoader(true);

    const serviceId = "service_oog4b95";
    const templateId = "template_ja57pfl";
    const publicKey = "1Z0o0FtJcA-6MM_WZ";

    const templateParam = {
      from_name: values.name,
      from_email: values.email,
      to_name: `414 Hub - ${name}`,
      message: values.message,
    };
    emailjs
      .send(serviceId, templateId, templateParam, publicKey)
      .then((response) => {
        setLoader(false);

        setMessage("The email was sent successfully");
        helpers.resetForm();
      })
      .catch((error) => {
        setLoader(false);
        setError("There was an error, try again");
      });
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        message: "",
      },
      validationSchema: noteShema,
      onSubmit,
    });

  return (
    <div className="py-6 pt-0">
      <div className="bg-white ">
        <form onSubmit={handleSubmit} className="mx-auto mt-8  max-w-xl ">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 ">
            <div className="sm:col-span-2">
              <label
                htmlFor="first-name"
                className=" text-lg font-semibold leading-6 text-gray-900 inline-block"
              >
                {" "}
                Name
              </label>
              <div className="mt-2.5">
                <input
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required=""
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="given-name"
                  placeholder="Your  Name"
                  className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-main-color sm:text-lg sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className=" text-lg font-semibold leading-6 text-gray-900 inline-block "
              >
                Email
              </label>
              {errors.email && touched.email && (
                <p className="text-red-600 ml-4 inline-block	">{errors.email}</p>
              )}
              <div className="mt-2.5">
                <input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required=""
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  placeholder="Your Email Address"
                  className={`block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-main-color  sm:text-lg sm:leading-6 ${
                    errors.email && touched.email
                      ? "border-l-8 border-red-600 focus:ring-0"
                      : ""
                  }`}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className=" text-lg font-semibold leading-6 text-gray-900 "
              >
                Message
              </label>
              <div className="mt-2.5">
                <textarea
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="message"
                  id="message"
                  rows="4"
                  placeholder="Share your thoughts..."
                  className="block w-full  border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2  focus:ring-main-color sm:text-lg sm:leading-6"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Button
              type="submit"
              id="primary"
              text1="Send Message"
              bgColor="bg-main-color"
              textColor="text-white"
              status={loader}
            />
            {message && (
              <p className="block text-xl mt-4 text-green-600">{message}</p>
            )}
            {error && (
              <p className="block text-xl mt-4 text-red-600">{error}</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;