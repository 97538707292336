import React from "react";
import Button from "../Button";
import { NavLink } from "react-router-dom";
const index = ({ img, text }) => {
  return (
    <>
      <header
        style={{
          backgroundImage: `url(${img})`,
        }}
        class={`w-full  h-[34rem] font-primary  bg-cover bg-center flex justify-center items-center relative`}
      >
        <div class="flex flex-col justify-start  absolute bottom-[46px] left-[50px]">
          <h1 class=" text-xl  md:text-2xl  lg:text-3xl text-black font-bold drop-shadow-lg w-2/3 md:w-2/4 bg-[#F6F7F7] p-4   md:p-10">
            {text}
          </h1>
        </div>
      </header>

      <div className="p-4 font-secondary text-center text-lg md:text-xl lg:text-2xl">
        <p className="mb-10 ">
          With rising advertising costs, relying solely on acquisition for your
          growth isn’t sustainable. You need an email strategy that maximizes
          your ROI to ensure that each customer contributes significantly to
          your revenue.
        </p>
        <div className="flex flex-col  items-center  justify-between">
          <div className="w-full  text-lg md:text-xl lg:text-2xl ">
            <h4 className="pl-12">
              ↳ That’s where
              <span className="font-primary font-bold text-main-color text-2xl px-1">
                The agency
              </span>
              comes in.
            </h4>
          </div>
          <div className="mt-4  ">
          <NavLink to="mailto:hello@fouronefour.co">
            <Button
              type="button"
              id="primary"
              text1="Call us for an intro"
              bgColor="bg-main-color "
              textColor="text-white"
            />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
