import React, { useEffect } from "react";
import Page404 from "../assets/imags/404.json";
import { NavLink } from "react-router-dom";
import Lottie from "lottie-react";
const NotFound = () => {
  useEffect(() => {
    document.title = "Page Not Found ";
  }, []);
  const style = {
    height: 300,
  };

  return (
    <div className="min-h-screen flex flex-grow items-center justify-center bg-gray-50">
      <div className=" bg-white p-8 text-center shadow-xl">
        <Lottie animationData={Page404} style={style} />
        <p className="text-gray-600">
          Oops! The page you are looking for could not be found.
        </p>
        <NavLink
          to="/"
          className="mt-4 inline-block  bg-main-color px-4 py-2 font-semibold text-gray-700 hover:bg-opacity-80"
        >
          {" "}
          Go back to Home{" "}
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
