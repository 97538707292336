import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Band from "../components/Band";
import Hero from "../components/Hero";
import astronaut from "../assets/imags/astronaut.gif";
import HorizontalText from "../components/Horizontal Text";
import Box from "../components/Box/Box";
import Form from "../components/Form";
import Ton from "../components/Project/Ton";
import MerciHandy from "../components/Project/MerciHandy";
import TwistedPlants from "../components/Project/TwistedPlants";
import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import ServicePlan from "../components/Service plan box/indexAgency";
const Home = () => {
  useEffect(() => {
    document.title = "The Agency - Home ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg8	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl "
          text="REAL REVENUE-DRIVING POWER BEGINS WITH UNDERSTANDING YOUR CUSTOMERS - KNOWING WHO THEY ARE, THINKING
        ABOUT WHAT THEY WANT - AND CONTINUES WITH DATA-INFORMED STRATEGIES AND GENUINE CONTENT."
        />

        <Hero
          img={astronaut}
          text={
            <>
              At{" "}
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium">
                {" "}
                The AGENCY
              </span>{" "}
              , we make sure that
              <span className="text-main-color text-xl  lg:text-3xl font-semibold  ">
                {" "}
                every ${" "}
              </span>
              invested
              <span className="text-main-color text-xl   lg:text-3xl font-semibold  ">
                {" "}
                brings you{" "}
              </span>
              a little
              <span className="text-main-color  text-xl  lg:text-3xl font-semibold ">
                {" "}
                more{" "}
              </span>
              each day, we use a datadriven approach to find what works best for
              driving
              <span className="text-main-color text-xl  lg:text-3xl font-semibold">
                {" "}
                growth{" "}
              </span>
              and
              <span className="text-main-color text-xl  lg:text-3xl font-semibold">
                {" "}
                maximizing{" "}
              </span>
              customer lifetime
              <span className="text-main-color  text-xl  lg:text-3xl font-semibold">
                {" "}
                value{" "}
              </span>
              and
              <span className="text-main-color text-xl  lg:text-3xl font-semibold">
                {" "}
                spending.{" "}
              </span>
            </>
          }
          btnText1="Let's  talk 💬"
        />
        <HorizontalText
          text={
            <>
              Leave ineffective , action-only
              <span className="underline">marketing </span> in the rearview and
              let’s work together! Leave ineffective, action-only marketing in
              the rearview and let’s work together!
            </>
          }
        />

<section className="bg-gray-2 pb-10 pt-20  lg:pb-20 lg:pt-[30px] px-4 py-6 text-cente ">
          <div className="grid justify-start items-start gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Box
              boxTitle="ADVERTISING 📈"
              boxDescription="We use a data-driven
              approach to experiment
              with various strategies,
              tactics, and channels to
              find what works best
              for driving growth."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/advertising"
            />
            <Box
              boxTitle="GROWTH 🚀 "
              boxDescription="We focus on building lasting customer relationships, maximizing customer lifetime value, and increasing spending through personalized strategies and data-driven insights."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/growth"
            />
            <Box
              boxTitle="Web stories  🤳🏼 "
              boxDescription="We make it easy for you to engage your audience with Web Stories. On mobile and desktop, they correspond to the new consumption habits of Internet users."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/web-stories"
            />
            <Box
              boxTitle="SEO ✍🏼 "
              boxDescription="We aim to answer the users’ questions and achieve rank 1 on the search engines. We help you create optimized content with a high-value proposition to establish your company as the industry’s standard."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/seo"
            />
            <Box
              boxTitle="Sales dev. 👨🏻‍💼 "
              boxDescription="With an extensive portfolio of retail partnerships, spanning from niche boutiques and upscale spas to major big-box retailers, our team stands ready to bolster your business."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/sales-dev"
            />
          </div>
        </section>

        <HorizontalText
          text={
            <>
              <span className="underline"> Projects </span> made with 🫶🏽 and
              delivering results for our clients. Projects made with 🫶🏽 and
              delivering results for our clients.
            </>
          }
        />

        <section className="w-full flex  flex-col gap-3  items-center pb-10 p-6">
          <MerciHandy />
          <TwistedPlants />
          <Ton />
          <NavLink to="#" className="my-10">
            <Button
              type="button"
              id="primary"
              text1="See"
              text2="More"
              icon="👀"
              bgColor="bg-main-color lg4 "
              textColor="text-white"
            />
          </NavLink>
        </section>

        <HorizontalText
          text={
            <>
              <span className="underline">Clear</span> and
              <span className="underline"> easy</span> pricing structure.
              no-hidden fees. No headaches 🤯.
            </>
          }
        />

        <section className=" overflow-hidden">
          <ServicePlan />
        </section>

        <Form />
        <Footer />
      </div>
    </>
  );
};

export default Home;
