import React from "react";
import { NavLink } from "react-router-dom";
const index = ({ link, img, title, text }) => {
  return (
    <>
      <div className="bg-main-color  w-full flex justify-start items-center lg:px-10 my-4">
        <div className="h-48 lg:h-auto w-32 md:w-56 flex-none bg-cover overflow-hidden flex justify-center items-center">
          <img src={img} alt="" />
        </div>
        <div className=" p-4 flex flex-col  leading-normal">
          <h1 className="text-white font-primary uppercase text-3xl font-semibold">
            {title}
          </h1>
          <p className="text-white font-secondary uppercase text-xl lg:text-2xl font-semibold">
            {text}
          </p>
        </div>
        <div className=" flex justify-center items-center text-white font-bold border-2 border-white p-1  mr-3 md:p-4 ">
          <NavLink
            to={link}
            className="  text-base md:text-4xl translate duration-75 cursor-pointer"
          >
            →
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default index;
