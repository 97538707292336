import React ,{useEffect} from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SeoHero from "../components/Hero/seo_Hero";
import Form from "../components/Form";
import HorizontalText from "../components/Horizontal Text";
import FeaturesSEO from "../components/Features/FeaturesSEO";
import SEOImg from "../assets/imags/_agency/SEO.jpg";
import SEOBox from "../components/Service plan box/seo";
const SEO = () => {
  useEffect(() => {
    document.title = "The Agency - SEO ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24 ">
      <Header />
      <Band
        styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
        textColor="text-font-color text-lg md:text-xl lg:text-2xl  "
        text="Scaling Revenue Through Organic SEO Traffic  "
      />
      <SeoHero
        img={SEOImg}
        text={
          <>
            <span class="text-main-color"> SEO is simple. </span> It’s a stable
            flow of traffic with{" "}
            <span class="text-main-color"> high buyer intent. </span>
          </>
        }
      />
      <section className="">
        <FeaturesSEO />
        <HorizontalText text="Clear and easy pricing structure. no-hidden fees. No headaches 🤯. " />
        <div className="mb-10">
        <SEOBox />
        </div>
        <Form />
      </section>

      <Footer /> </div>
    </>
  );
};

export default SEO;
