import React, { useState, useRef, useEffect } from "react";
import freepeopleLogo from "../../assets/imags/_agency/free people logo.png";
import ultabeautyLogo from "../../assets/imags/_agency/ulta beauty logo.webp";
import Marquee from "react-fast-marquee";
import USRetailLaunch from "../../assets/imags/_agency/US Retail Launch.jpg";
import RetailAccountManagement from "../../assets/imags/_agency/Retail Account Management.jpg";
import USMarketSalesStrategy from "../../assets/imags/_agency/US Market Sales Strategy.jpg";
import slideOne from "../../assets/imags/_agency/merci handy logo.png";
import slideTow from "../../assets/imags/_agency/minori logo.jpg";
import slideThree from "../../assets/imags/_agency/tony and guy logo.png";
import slidefour from "../../assets/imags/_agency/KIND-LY.png";
import slidefive from "../../assets/imags/_agency/boho green logo.jpg";

export const ImageTextBox = ({ text, img, alt }) => {
  return (
    <section className="w-full my-20">
      <div className="w-full">
        <img
          src={img}
          alt={alt}
          className=" object-cover w-[100%] lg:h-[500px]"
        />
      </div>
      <p className="text-2xl">{text}</p>
    </section>
  );
};
const Features = () => {
  return (
    <>
      <div className="flex flex-col jus items-center w-full px-6 lg:px-32 mb-10">
        <div className="self-end w-full">
          <h1 className="font-primary self-end text-2xl  md:text-2xl lg:text-3xl text-main-color">
            a few <span className="text-black">brands</span> we worked with.
          </h1>

          <div>
            <Marquee direction="left" speed={100} delay={2}>
              <img
                src={slideOne}
                alt="merci handy"
                className="h-[200px] w-[300px] p-8 inline-flex mr-6"
              />
              <img
                src={slideTow}
                alt="minori"
                className="h-[200px] w-[300px] p-8 inline-flex mr-6"
              />
              <img
                src={slideThree}
                alt="tony and guy"
                className="h-[150px] w-[300px] p-8 inline-flex mr-6"
              />
              <img
                src={slidefour}
                alt="KIND-LY"
                className="h-[200px] w-[300px] p-8 inline-flex mr-6"
              />
              <img
                src={slidefive}
                alt="boho green "
                className="h-[200px] w-[300px] p-8 inline-flex mr-6"
              />
            </Marquee>
          </div>
          <h1 className="font-primary self-end text-2xl  md:text-2xl lg:text-3xl text-main-color">
            a few <span className="text-black">retailers</span> we worked with.
          </h1>

          <Marquee direction="left" speed={100} delay={2}>
            <img
              src={freepeopleLogo}
              alt="merci handy logo"
              className="h-[200px] w-[300px] p-8 inline-flex mr-6"
            />
            <img
              src={ultabeautyLogo}
              alt="Mailchimp Trans"
              className="h-[200px] w-[300px] p-8 inline-flex mr-6"
            />
          </Marquee>
        </div>
      </div>
      <div className="mb-32 px-6 ">
        <h1 className="font-primary text-2xl  md:text-2xl lg:text-3xl text-black mb-10">
          What <span className=" text-main-color "> we </span> can{" "}
          <span className="text-main-color "> do </span> for you
        </h1>
        <ImageTextBox
          img={USRetailLaunch}
          alt="US Retail Launch"
          text={
            <>
              <h1 className="font-bold text-3xl pt-10"> US Retail Launch</h1>
              The US market, expansive and intricate, necessitates thorough
              preparation for a successful entry of your brand. Leveraging our
              expertise, we lead you through the process, ensuring your brand
              launches with optimum visibility and impact.
              <ul className="list-none pt-10 pl-10">
                <li>▸ GO-TO-MARKET PLANNING</li>
                <li>▸ ASSET CONSULTATION</li>
                <li>▸ LINE PLANNING FOR US BUYERS</li>
              </ul>
            </>
          }
        />
        <ImageTextBox
          img={RetailAccountManagement}
          alt="Retail Account Management  "
          text={
            <>
              <h1 className="font-bold text-3xl pt-10">
                {" "}
                Retail Account Management
              </h1>
              Securing access to crucial opportunities is merely the initial
              stride. A flourishing retail collaboration demands ongoing
              resources and support to sustain the growth of your sales. We
              handle the daily operations, enabling you to direct your attention
              to your brand.
              <ul className="list-none pt-10 pl-10">
                <li>▸ EXPERT ACCOUNT MANAGEMENT</li>
                <li>▸ MAXIMIZE RETAIL OPPORTUNITIES</li>
                <li>▸ WHOLESALE MARKETING PLANNING</li>{" "}
                <li>▸ STRATEGIC ACCOUNT OUTREACH</li>{" "}
                <li>▸ ORDER PROCESSING</li>{" "}
                <li>▸ INVOICING + ACCOUNTS RECEIVABLE</li>
              </ul>
            </>
          }
        />
        <ImageTextBox
          img={USMarketSalesStrategy}
          alt="US Market Sales Strategy"
          text={
            <>
              <h1 className="font-bold text-3xl pt-10">
                {" "}
                US Market Sales Strategy
              </h1>
              Strategically targeting suitable retail partnerships requires a
              realistic and thoughtful approach. With our guidance, your brand
              will be presented to the desired buyers, employing an effective
              strategy that delivers results.
              <ul className="list-none pt-10 pl-10">
                <li>▸ GO-TO-MARKET WHOLESALE STRATEGY</li>
                <li>▸ USA BRAND POSITIONING</li>
                <li>▸ BRAND AUDIT FOR US MARKET</li>
                <li>▸ CONSULTING PACKAGES</li>
              </ul>
            </>
          }
        />
      </div>
    </>
  );
};

export default Features;
