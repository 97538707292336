import React from "react";
import Button from "../Button";
import NoteForm from "../../components/Form/note";

import {handleCheckout} from "./helpers"
const index = () => {




  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
        crm marketing 👨🏻‍💻 
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
          <span className="text-main-color"> Maximizing </span>  
           customers’ <span className="text-main-color"> spend </span>  and <span className="text-main-color"> value </span> , nurturing <span className="text-main-color"> engagement. </span> 
      
          </p>
          <p className=" text-left font-secondary">
          We focus on building lasting customer relationships, maximizing customer lifetime value, and increasing spending 
          through personalized strategies and data-driven insights.
          </p>
    
          {/* <div className="grid grid-cols-5 gap-4">
            <div className="col-span-2 text-base font-primary text-main-color md:text-lg ">
            Email/text Marketing
            </div>
            <div className="col-span-3 flex flex-col ">
              <Button
                type="button"
                id="primary"
                text1="$2,750/month"
                bgColor="bg-main-color"
                textColor="text-white"
                fontSize="text-base "
                onClick={() => handleCheckout(1500 ," CRM marketing")}  
              />
    
            </div>
          </div> */}
          <div className="grid grid-cols-12 bg-white pb-4  ">
            <div className="col-span-12 h-[1px] bg-black mb-2"></div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              What’s included?
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑   Collaborative workspace.
            </div>

            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Weekly reports.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑  Audits (campaigns, landing pages, etc.).
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑   Segmentation and Targeting.
            </div>
            <div className="col-span-12 text-base md:text-lg lg:text-xl flex items-center font-light pl-2">
              ☑   Automated Campaigns.
            </div>
      
          </div>
          <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
            <NoteForm name=" Crm Marketing " />
        </div>
      </div>
    </div>
  );
};

export default index;
