import React from "react";
import mailchimpTrans from "../../assets/imags/_agency/mailchimp-trans.webp";
import klaviyo from "../../assets/imags/_agency/klaviyo.png";
import Marquee from "react-fast-marquee";
const Features = () => {
  return (
    <>
      <div className="flex flex-col  items-center w-full p-8   mb-10">
        <div className="self-end w-full">
          <h1 className="font-primary self-end text-2xl  md:text-2xl lg:text-3xl text-main-color">
            Official Partners <span className="text-black"> with the best</span>
          </h1>
          <div className="flex flex-row justify-evenly py-12">
            <Marquee direction="left" speed={100} delay={2}>
              <img
                src={klaviyo}
                alt="mailchimp Trans"
                className="h-[200px] w-[400px] p-8 inline-flex mr-6"
              />
              <img
                src={mailchimpTrans}
                alt="Mailchimp Trans"
                className="h-[200px] w-[400px] p-8 inline-flex mr-6"
              />
            </Marquee>
          </div>
          <h1 className="font-primary text-2xl  md:text-2xl lg:text-3xl text-main-color mb-10">
            Cracking <span className="text-black"> the email+text </span> game
          </h1>
        </div>

        <h1 className="font-primary text-2xl  md:text-2xl  lg:text-3xl text-main-color ">
          A 360° approach to your CRM marketing.
        </h1>
        <div className="py-4 flex flex-col md:flex-row justify-evenly gap-2 lg:gap-10 text-lg md:text-xl lg:text-2xl">
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_3)] p-4">
            <span className="font-bold">🕵🏻‍♂️ Segmented </span>campaigns to
            maximize impact, ROI and LTV
          </div>
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_3)] p-4">
            <span className="font-bold">🤖 Automated</span> workflows for
            personalized interaction, nurturing customer relationships.
          </div>
          <div className="bg-[#f7f7f7] w-full md:w-[calc(100%_/_3)] p-4">
            <span className="font-bold">🛒 Retention</span> (re)igniting
            interest and extending the LTV of customers in your CRM.
          </div>
        </div>
        <div className="px-8 lg:px-20">
          {" "}
          <h1 className=" font-secondary text-center font-light  text-lg md:text-xl lg:text-2xl py-5">
            ↳ Our objective is to propel brand growth through top-notch email
            and retention marketing, enabling you to concentrate on the crucial
            aspects of your business’s well-being.
          </h1>
          <h1 className=" font-secondary text-center font-bold  text-lg md:text-xl lg:text-2xl  pt-5">
            Consider us more than just an extension of your team—we’re like
            extended family.{" "}
          </h1>
        </div>
      </div>
    </>
  );
};

export default Features;
