import React from "react";
import NoteForm from "../../components/Form/note";
const index = () => {
  return (
    //
    <div className=" flex justify-center items-center bg-white my-4 text-lg md:text-xl lg:text-2xl">
      <div className="w-[20rem]  shadow-xl flex flex-col text-font-color  bg-main-color p-1">
        <p className=" font-semibold text-white text-center text-4xl font-primary  px-2 py-2">
          SEO ✍🏼
        </p>

        <div className="flex flex-col pt-8 bg-white px-9 gap-3">
          <p className="text-xl md:text-2xl lg:text-3xl font-semibold font-primary">
            Do you want more <span className="text-main-color"> traffic? </span>{" "}
            Get an in depth SEO audit & playbook!
          </p>
          <p className=" text-left font-secondary">
            We aim to answer the users’ questions and achieve rank 1 on the
            search engines. We help you create optimized content with a
            high-value proposition to establish your company as the industry’s
            standard.
          </p>

          <div className="flex flex-row justify-between items-center gap-2">
            <div className="col-span-2  font-primary text-main-color md:text-2xl ">
              Contact us
            </div>
            <div className="col-span-2 text-base font-primary text-black md:text-lg ">
              drop us a note 👇📝
            </div>
          </div>
          <NoteForm name="SEO" />
        </div>
      </div>
    </div>
  );
};

export default index;
