import React ,{useEffect} from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WebStoriesHero from "../components/Hero/webStories_Hero";
import Form from "../components/Form";
// import HorizontalText from "../components/Horizontal Text";
import FeaturesWebStories from "../components/Features/FeaturesWebStories";
import webStories from "../assets/imags/_agency/webStories.png";
import WebStoriesBox from "../components/Service plan box/web stories";

const WebStories = () => {
  useEffect(() => {
    document.title = "The Agency - Web Stories ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24 ">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl  "
          text="Give your brand a unique voice 🗣️ with Web Stories 🤳🏼 "
        />
        <WebStoriesHero
          img={webStories}
          text={
            <>
              The power of storytelling to create
              <span class="text-main-color"> engaging experiences. </span>
            </>
          }
        />
        <section className=" ">
          <FeaturesWebStories />
          <div className="mb-10">
            <WebStoriesBox />
          </div>
          <Form />
        </section>
        <Footer />
      </div>
    </>
  );
};

export default WebStories;
