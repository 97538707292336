import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Band from "../components/Band";
import Hero from "../components/Hero";
import HorizontalText from "../components/Horizontal Text";
import Form from "../components/Form";
import heroImg from "../assets/imags/the-factory-homepage.webp";
import Box from "../components/Box/Box";
import ServicePlan from "../components/Service plan box/indexFactory";
import AllProjexts from "../components/Project";
import shuffle from "lodash/shuffle";

const Home = () => {
  useEffect(() => {
    document.title = "The Factory  - Home ";
  }, []);
  return (
    <>
      <div className=" container mx-auto px-8 lg:px-24">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:8	"
          textColor="text-font-color text-xl  md:text-2xl  lg:text-3xl  "
          text="Never run out of creatives and organic content - We develop a custom strategy,
           select quality creators, edit the assets, and deliver you weekly."
        />
        <Hero
          img={heroImg}
          text={
            <>
              At {""}
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium ">
                The Factory
              </span>
              , we create content that people actually want to see. We’re here
              to{" "}
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium ">
                drive engagement
              </span>
              , build trust, optimize {" "}
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium ">
                visibility
              </span>{" "}
              and ultimately boost{" "}
              <span className="text-main-color font-primary text-xl lg:text-3xl  font-medium ">
                growth.
              </span>
            </>
          }
          btnText1="Let's  talk 💬"
     
        />

        <HorizontalText
          text={
            <>
              let’s craft a compelling narrative that drives real engagement and
              lasting growth!
            </>
          }
        />

        <section className="bg-gray-2 pb-10 pt-20  lg:pb-20 lg:pt-[30px] px-4 py-6 text-cente ">
          <div className="grid justify-start items-start gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <Box
              boxTitle="ugc 🎥
                "
              boxDescription="Tap into a diverse range of perspectives and experiences to the forefront, fostering a sense of inclusivity and diversity."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/ugc"
            />
            <Box
              boxTitle="Social media 📸
                "
              boxDescription="Our creative team will help make your voice heard and resonate on
                 social media channels through our in-house content creation studio."
              btnText1="See"
              btnText2="More"
              icon="👀"
              path="/social-media"
            />
          </div>
        </section>

        <HorizontalText
          text={
            <>
              <span className="underline"> Projects </span> made with 🫶🏽 and
              delivering results for our clients. Projects made with 🫶🏽 and
              delivering results for our clients.
            </>
          }
        />
        <div>
          <AllProjexts />
        </div>
        <HorizontalText
          text={
            <>
              <span className="underline">Clear</span> and
              <span className="underline"> easy</span> pricing structure.
              no-hidden fees. No headaches 🤯.
            </>
          }
        />

        <section className=" overflow-hidden">
          <ServicePlan />
        </section>
        <Form />
        <Footer />
      </div>
    </>
  );
};

export default Home;
