import React from "react";
import Pitch from "../../assets/imags/Pitch on air.png";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden shadow-lg border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit capitalize  font-bold text-lg md:text-2xl lg:text-4xl  mb-2">
          Pitch On Air 
          </div>
          <div className=" w-fit font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
            UX/UI 🖼️
          </div>
        </div>
        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-center "
          src={Pitch}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-4 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700 pb-10 ">
            Pitch On Air is a{" "}
            <span className="text-main-color font-extrabold">
              web app for honing communication skills
            </span>{" "}
            through realistic exercises. It offers personalized feedback and
            recommendations by analyzing both verbal and non-verbal aspects in
            real-life scenarios on its platform. The company brought our team in
            to{" "}
            <span className="text-main-color font-extrabold">
              redesign a new user interface
            </span>{" "}
            and{" "}
            <span className="text-main-color font-extrabold">
              customer-facing website
            </span>{" "}
            to give a more professional design to their MVP.
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
            Built a{" "}
            <span className="text-main-color font-extrabold">
              new user interface
            </span>{" "}
            ,{" "}
            <span className="text-main-color font-extrabold">
              website user experience{" "}
            </span>{" "}
            through interactive mockups!
          </p>
        </div>
        <div className="bg-[#F7F7F7] py-8 px-4 m-2 lg:m-20">
          <p className=" font-secondary text-base md:text-lg lg:text-2xl font-thin italic text-center  leading-normal">
            Pitch On Air is the right tool 🧰 to help practicing and improving
            communication 💬 skills with personalized feedback 🤖.
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
