import React ,{useEffect} from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import GrowthHero from "../components/Hero/growth_Hero";
import Form from "../components/Form";
import HorizontalText from "../components/Horizontal Text";
import FeaturesGrowth from "../components/Features/FeaturesGrowth";
import growthMarketingEmail from "../assets/imags/_agency/growthMarketingEmail.jpg";
import CRMMarketingBox from "../components/Service plan box/crm marketing";
const Growth = () => {
  useEffect(() => {
    document.title = "The Agency - Growth ";
  }, []);
  return (
    <>
      <div className=" container mx-auto  lg:px-24 ">
        <Header />

        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-lg md:text-xl lg:text-2xl  "
          text="Boost CLTV and improve retention with strategic email+SMS marketing "
        />
        <GrowthHero
          img={growthMarketingEmail}
          text={
            <>
              <span class="text-main-color"> Emails + texts </span> that
              convert, <span class="text-main-color">retention</span> +{" "}
              <span class="text-main-color"> CRM </span> marketing that
              translates into revenue.
            </>
          }
        />
        <section className="">
          <FeaturesGrowth />
          <HorizontalText
            text={
              <>
                <span className="underline">Clear</span> and
                <span className="underline"> easy</span> pricing structure.
                no-hidden fees. No headaches 🤯.
              </>
            }
          />{" "}
         <div className="mb-10">
            <CRMMarketingBox />
          </div>
          <Form />
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Growth;
