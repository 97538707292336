import React, { useState } from "react";
import { NavLink } from "react-router-dom";
export default function Dropdown(props) {
  const { item  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const menuItems = item?.children ? item.children : [];

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const transClass = isOpen ? "flex" : "hidden";

  return (
   
      <div

        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink
          style={({ isActive }) =>
            isActive
              ? {
                  color: "#ffba5b",
                  background: "none",
                }
              : { color: "black", background: "none" }
          }
          to={item.route}
          className="flex justify-center items-center gap-2 p-4 transition hover:opacity-75"
        >
          {item.title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 8 5"
            fill="none"
          >
            <line
              y1="-0.25"
              x2="5.59038"
              y2="-0.25"
              transform="matrix(0.715542 0.698569 -0.6013 0.799023 0 0.905273)"
              stroke="black"
              strokeWidth="0.5"
            />
            <line
              y1="-0.25"
              x2="5.59038"
              y2="-0.25"
              transform="matrix(-0.715542 0.698569 0.6013 0.799023 8 0.905273)"
              stroke="black"
              strokeWidth="0.5"
            />
          </svg>
        </NavLink>
        <div
          className={` absolute top-10 z-30 w-[250px]  h-fit flex flex-col py-4 bg-white shadow-md  ${transClass}`}
        >
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              className="hover:bg-orange-300 px-4 py-1 "
              to={item?.route || ""}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    
  );
}
