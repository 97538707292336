import React from "react";
import TwistedPlants from "../../assets/imags/_agency/twisted plants.jpg";
const index = () => {
  return (
    <div className=" grid grid-cols-1 gap-5 ">
      <div className=" overflow-hidden shadow-lg border-t-8 border-t-font-color pt-4">
        <div className="flex justify-between items-center px-2 ">
          <div className=" w-fit   font-bold text-lg md:text-2xl lg:text-4xl  mb-2">
            {" "}
            Twisted plants
          </div>
          <div className=" w-fit font-bold text-base md:text-2xl text-main-color  mb-2 uppercase  text-right">
          CRM MKTG  🧑‍💻 + ADVERTISING 🚀 
          </div>
        </div>

        <img
          className="w-full h-[145px] md:h-[480px] object-cover object-top	 "
          src={TwistedPlants}
          alt="Merci-Handy-Hand-Sanitizer"
        />
        <div className="px-2 py-4 text-base md:text-lg lg:text-2xl text-font-color">
          <p className="text-gray-700  pb-10 ">
            Our team designed and built a local POS system for online activity;
            Twisted Plants was able to accept orders, track sales 📈 , know
            their customers, manage reports 📊 , and let people know when orders
            are ready in one tap. A smooth and super easy online ordering
            experience for their customers, without leaving Instagram, Facebook
            or Google. 📲
          </p>

          <p className="border-t-8 border-t-main-color py-1 ">
            <span className="text-main-color font-extrabold">Switch</span> from
            dine-in{" "}
            <span className="text-main-color font-extrabold"> to online </span>{" "}
            during pandemic constraints at $0 cost.{" "}
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            <span className="text-main-color font-extrabold"> From $0</span> in
            online sales{" "}
            <span className="text-main-color font-extrabold">to</span>
            average of{" "}
            <span className="text-main-color font-extrabold">
              {" "}
              $45,000/month
            </span>{" "}
            within 2 months.
          </p>
          <p className="border-t-8 border-t-main-color py-1 ">
            <span className="text-main-color font-extrabold"> +40% </span> more{" "}
            <span className="text-main-color font-extrabold"> tips 💰</span> &
            +26% average{" "}
            <span className="text-main-color font-extrabold">
              {" "}
              $ transaction. 📈
            </span>
          </p>
        </div>
        <div className="bg-[#F7F7F7] py-8 px-4 m-2 lg:m-20">
          <p className=" font-secondary text-base md:text-lg lg:text-2xl font-thin italic text-center  leading-normal">
            Twisted Plants is a local Milwaukee plant-based 🥑 restaurant who
            grew from a food truck to 1 food truck and 2 restaurant locations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default index;
