import React from "react";
import Band from "../components/Band";
import Footer from "../components/Footer";
import Header from "../components/Header";
import UGCHero from "../components/Hero/UGC_Hero";
import Form from "../components/Form";
import HorizontalText from "../components/Horizontal Text";
import FeaturesUGC from "../components/Features/FeaturesUGC";
import UGCImg from "../assets/imags/_factory/UGC.jpg";
import UGCBox from "../components/Service plan box/UGC";
const SEO = () => {
  return (
    <>
      <div className=" container mx-auto  lg:px-24 overflow-hidden">
        <Header />
        <Band
          styleBand="bg-main-color py-4 px-4 sm:px-8 lg:px-18	"
          textColor="text-font-color text-xl  md:text-2xl  lg:text-3xl "
          text="UGC 🤳🏼 is the indispensable advertising format in 2023  "
        />
        <UGCHero
          img={UGCImg}
          text={
            <>
              It’s time to <span class="text-main-color"> get real</span> about
              your <span class="text-main-color"> UGC.</span>
            </>
          }
        />
        <section className="">
          <FeaturesUGC />
          <HorizontalText text="Clear and easy pricing structure. no-hidden fees. No headaches 🤯. " />
          <div className="mb-10">
            <UGCBox />
          </div>
          <Form />
        </section>
        <Footer />{" "}
      </div>
    </>
  );
};

export default SEO;
